import ISO6391 from 'iso-639-1';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import copyDocumentTitleOnWeb from '../../../../shared/foreground/copyDocumentTitleOnWeb';
import copyDocumentUrlOnWeb from '../../../../shared/foreground/copyDocumentUrlOnWeb';
import { generateEmailWarningMessage, messageCopy } from '../../../../shared/foreground/messageCopy';
import { removeDocsFromTheSameEmailAddress, toggleEmailSubscription } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/emails';
import { addFeed, removeFeed } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { useDocRSSFeed } from '../../../../shared/foreground/useDocRSSFeed';
import { useMatchingRSS } from '../../../../shared/foreground/useMatchingRss';
import { getReadingPositionInfo } from '../../../../shared/foreground/utils/getReadingPositionInfo';
import useGlobalStateWithFallback from '../../../../shared/foreground/utils/useGlobalStateWithFallback';
import { useSummary } from '../../../../shared/summary';
import { AnyDocument, Category, CategoryToDisplayName, RssFeed, ShortcutId } from '../../../../shared/types';
import {
  isDocumentWithPublishedDate,
  isDocumentWithThirdPartyUrl,
  isDocumentWithUrl,
} from '../../../../shared/typeValidators';
import { formatDateForFilter } from '../../../../shared/utils/dates/formatDateForFilter';
import formatPublishedDate from '../../../../shared/utils/dates/formatPublishedDate';
import getFormattedDurationFromNow from '../../../../shared/utils/dates/getFormattedDurationFromNow';
import getDisplayLength from '../../../../shared/utils/getDisplayLength';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentAuthorHandle from '../../../../shared/utils/getDocumentAuthorHandle';
import getDocumentCategoryOverrideOrReal from '../../../../shared/utils/getDocumentCategoryOverrideOrReal';
import getDocumentLanguage from '../../../../shared/utils/getDocumentLanguage';
import getDocumentOverrideOrReal from '../../../../shared/utils/getDocumentOverrideOrReal';
import getDocumentPublishedDate from '../../../../shared/utils/getDocumentPublishedDate';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import getTimeFromStringOrNumberDate from '../../../../shared/utils/getTimeFromStringOrNumberDate';
import getUrlDomain from '../../../../shared/utils/getUrlDomain';
import useLeaveReadingViewIfOpen from '../../hooks/useLeaveReadingViewIfOpen';
import { useShortcutsMap } from '../../utils/shortcuts';
import BabyGhost from '../BabyGhost';
import CopyButton from '../CopyButton';
import { NotebookCoverImage } from '../CoverImage/NotebookCoverImage';
import { DeleteFeedDialog } from '../DeleteFeedDialog';
import FeedAnimatedIcon from '../icons/FeedAnimatedIcon';
import PersonCard from '../PersonCard';
import Tag from '../Tag';
import Tooltip from '../Tooltip';
import type { DocumentPanelProps } from './RightSidebar';
import styles from './RightSidebar.module.css';
import { Summary } from './Summary';

const SubscribeButton = ({
  isSubscribed,
  onClick,
  feedTitle,
  emailAddress,
}: { isSubscribed: boolean; onClick: () => void; feedTitle: string; emailAddress?: string; }) => {
  const shortcutsMap = useShortcutsMap();
  const [playRSSButtonAnimation, setPlayRSSButtonAnimation] = useState(false);
  const [extraClass, setExtraClass] = useState(isSubscribed ? `${styles.unsubscribable}` : '');

  useEffect(() => {
    setExtraClass(isSubscribed ? `${styles.unsubscribable}` : '');
  }, [isSubscribed]);

  const innerOnClick = useCallback(() => {
    if (!isSubscribed) {
      // we are subscribing
      setPlayRSSButtonAnimation(true);
    } else {
      // we're unsubscribing
      setExtraClass('');
      setPlayRSSButtonAnimation(false);
    }
    onClick();
  }, [isSubscribed, onClick]);

  const onMouseOutHandler = () => {
    if (playRSSButtonAnimation && !extraClass) {
      setExtraClass(`${styles.unsubscribable}`);
    }
  };

  const buttonCopy = useMemo(() => isSubscribed ? '' : 'Subscribe', [isSubscribed]);
  const contentCopy = useMemo(() => emailAddress || feedTitle || '', [emailAddress, feedTitle]);

  return <Tooltip
    content={contentCopy} shortcut={shortcutsMap[ShortcutId.ToggleRssOrEmailSubscription]}
    key={`feed-tooltip-${feedTitle}`}>
    <button
      type="button"
      className={`${styles.documentRssSubBtn} ${isSubscribed ? styles.subscribed : ''} ${extraClass}`}
      onClick={innerOnClick} onMouseOut={onMouseOutHandler} onBlur={onMouseOutHandler}>
      <FeedAnimatedIcon animate={playRSSButtonAnimation} />
      {buttonCopy}
    </button>
  </Tooltip>;

};
export const DocumentInfoPanel = ({ sidebarsHidden, document, isNotebookView }: DocumentPanelProps & {
  isNotebookView?: boolean;
}) => {
  const author = getDocumentAuthor(document);
  const language = getDocumentLanguage(document);
  const { possibleRss, subscribed: isSubscribed } = useMatchingRSS(document);
  const allRssFeeds = useGlobalStateWithFallback({}, useCallback((state) => state.persistent.rssFeeds, []));
  const feedId = Object.entries(allRssFeeds).filter((entry) => entry[1].url === possibleRss?.url)?.[0]?.[0];
  const [deleteFeedDialogOpen, setDeleteFeedDialogOpen] = useState(false);
  const allEmailSubscriptions = useGlobalStateWithFallback({}, useCallback((state) => state.persistent.emailSubscriptions, []));
  const imageUrl = getDocumentOverrideOrReal(document, 'image_url');
  const leaveReadingViewIfOpen = useLeaveReadingViewIfOpen();
  const displayLength = getDisplayLength(document);

  let lengthInfo: JSX.Element | undefined;
  if (isNotebookView) {
    lengthInfo = <div className={styles.metaDataRow}>
      <div className={styles.metaDataName}>
        Length
      </div>
      <div className={styles.metaDataValue}>
        {document.children.length || '0'} Highlights
      </div>
    </div>;
  } else if (displayLength) {
    lengthInfo = <div className={styles.metaDataRow}>
      <div className={styles.metaDataName}>
        Length
      </div>
      <div className={styles.metaDataValue}>
        {displayLength}
      </div>
    </div>;
  }

  const readingProgressInfo = <div className={styles.metaDataRow}>
    <div className={styles.metaDataName}>
      Progress
    </div>
    <div className={styles.metaDataValue}>
      {getReadingPositionInfo(document)}
    </div>
  </div>;

  const { summary, generate, isGenerating, isGenerated } = useSummary(document);
  const [hasGenerateSummaryButton, setHasGenerateSummaryButton] = useState(false);
  const babyGhostClassNames = isGenerating ? [styles.ghostAnimation, styles.generating] : [styles.ghostAnimation];

  let summaryInfo: JSX.Element | undefined;
  if (summary) {
    const maxSummaryLength = 900;
    let summaryToShow = summary;
    if (summaryToShow.length > maxSummaryLength) {
      summaryToShow = `${summaryToShow.substr(0, maxSummaryLength - 3)}...`;
    }
    summaryInfo =
      <div
        className={styles.summary}
        onMouseOver={() => setHasGenerateSummaryButton(true)}
        onFocus={() => setHasGenerateSummaryButton(true)}
        onMouseOut={() => setHasGenerateSummaryButton(false)}
        onBlur={() => setHasGenerateSummaryButton(false)}
      >
        <div className={styles.subheading}>
          Summary
          <Tooltip content="Summarize with GPT-3.5" placement="left">
            <BabyGhost
              className={babyGhostClassNames.join(' ')}
              isShown={hasGenerateSummaryButton || Boolean(isGenerating)}
              onClick={generate} />
          </Tooltip>
        </div>
        <span lang={language}>
          <Summary isGenerating={isGenerating} isGenerated={isGenerated}>{summaryToShow}</Summary>
        </span>
      </div>;
  }

  const publishedDate = getDocumentPublishedDate(document);
  const formattedPublishedDate = useMemo(() => typeof publishedDate === 'number' ? formatPublishedDate(publishedDate) : '', [publishedDate]);

  const publishedDateForFilter = isDocumentWithPublishedDate(document) && publishedDate && formatDateForFilter(getTimeFromStringOrNumberDate(publishedDate));

  const publishedDateLink = publishedDateForFilter
    ? `/filter/published:"${publishedDateForFilter}"`
    : '';

  const savedAtDateFromNow = document.saved_at
    ? getFormattedDurationFromNow(Number(document.saved_at))
    : '';

  const savedAtDateForFilter = document.saved_at
    ? formatDateForFilter(document.saved_at)
    : '';

  const savedDateLink = savedAtDateForFilter ? `/filter/saved:"${savedAtDateForFilter}"` : '';
  const languageLink = language !== 'Unknown' ? `/filter/language:"${language}"` : '';

  const tagInfo = map(
    document.tags,
    ({ name, created, type }) =>
      <Link
        key={`${name}-${created}`}
        to={`/filter/tag:"${encodeURIComponent(name)}"`}
      >
        <Tag hasHoverStyle>{type === 'generated' ? `#${name}` : name}</Tag>
      </Link>,
  );

  const url = isDocumentWithThirdPartyUrl(document) && document.url || undefined;
  const originUrl = url ? getUrlDomain(url) : undefined;
  const authorHandle = getDocumentAuthorHandle(document);
  const docRssFeed = useDocRSSFeed(document);

  const RssFeedName = ({ docFeed }: { docFeed?: RssFeed; }) => {
    const docFeedId = document.source_specific_data?.rss_feed;
    const rssFeedName = docFeed ? docFeed?.name : undefined;

    if (!rssFeedName) {
      return null;
    }

    return (
      <Link to={`/filter/rssSource:"${docFeedId}"`} className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
        <div className={styles.metaDataName}>
          Source
        </div>
        <div className={styles.metaDataValue}>
          {rssFeedName}
        </div>
      </Link>
    );
  };

  const languageLabel = useMemo(() => {
    return language === undefined ? undefined : ISO6391.getName(language);
  }, [language]);

  const category = useMemo(() => getDocumentCategoryOverrideOrReal(document), [document]);
  const capitalizedCategory = CategoryToDisplayName[category];

  const emailAddress = document?.source_specific_data?.email?.from_email ?? '';
  const emailIsSubscribed = emailAddress ? allEmailSubscriptions[emailAddress]?.subscribed ?? false : false;

  const emailSpecificWarningMessage = useMemo(() => generateEmailWarningMessage(emailAddress), [emailAddress]);

  const subscribeToEmailOrRSSCallback = useCallback(async () => {
    if (!isSubscribed && possibleRss) {
      await addFeed(possibleRss, { userInteraction: 'sidebar-button-click' });
    } else if (!emailIsSubscribed && !possibleRss && emailAddress) {
      await toggleEmailSubscription(emailAddress, emailIsSubscribed, { userInteraction: 'sidebar-button-click' });
    } else if (possibleRss && feedId || emailIsSubscribed) {
      setDeleteFeedDialogOpen(true);
    }
  }, [emailAddress, emailIsSubscribed, setDeleteFeedDialogOpen, feedId, isSubscribed, possibleRss]);

  const feedTitle = possibleRss && possibleRss.name ? possibleRss.name
    : possibleRss && !possibleRss.name ? possibleRss.url
    : document.author || '';

  return <div
    className={`${styles.content} ${sidebarsHidden ? styles.hidden : ''}`}>
    {isNotebookView && <div style={{ textAlign: 'center' }}>
        <NotebookCoverImage imageUrl={imageUrl} category={category} />
    </div>}
    <div className={styles.header}>
      <DocTitle doc={document} />
      {!isNotebookView && originUrl && <span className={styles.articleSource}>
        <a href={`${url}`} target="_blank" rel="noreferrer">{originUrl}</a>
        <CopyButton className={styles.copyButton} onClick={() => copyDocumentUrlOnWeb(document.id)} />
      </span>}
    </div>
    <PersonCard
      name={author}
      additionalText={emailAddress !== '' ? emailAddress : authorHandle}
    />
    {!isNotebookView && (possibleRss || emailAddress) && <div>
        <SubscribeButton
          key={
            `${category}-subscribe-button-${possibleRss ? possibleRss.url : document.author}`
          }
          feedTitle={feedTitle}
          emailAddress={emailAddress}
          isSubscribed={isSubscribed || emailIsSubscribed}
          onClick={subscribeToEmailOrRSSCallback}
        />
    </div>}
    {summaryInfo}
    {tagInfo.length > 0 && <>
        <div className={styles.subheading}>DOCUMENT TAGS</div>
        <div className={styles.tagsContainer}>
          {tagInfo}
        </div>
    </>
    }
    <div className={styles.subheading}>METADATA</div>
    <div className={styles.metaData}>
      <Link
        to={`/filter/type:${category}`}
        className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
        <div className={styles.metaDataName}>
          Type
        </div>
        <div className={styles.metaDataValue}>
          {capitalizedCategory}
        </div>
      </Link>
      {document.category === Category.RSS && <RssFeedName docFeed={docRssFeed} />}
      {isDocumentWithUrl(document) &&
          <Link to={`/filter/domain:"${originUrl}"`} className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
              <div className={styles.metaDataName}>
                  Domain
              </div>
              <div className={styles.metaDataValue}>
                {originUrl}
              </div>
          </Link>
      }
      <Link to={publishedDateLink} className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
        <div className={styles.metaDataName}>
          Published
        </div>
        <div className={styles.metaDataValue}>
          {formattedPublishedDate}
        </div>
      </Link>
      {lengthInfo}
      <Link to={savedDateLink} className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
        <div className={styles.metaDataName}>
          Saved
        </div>
        <div className={styles.metaDataValue}>
          {savedAtDateFromNow}
        </div>
      </Link>
      {readingProgressInfo}
      <Link to={languageLink} className={`${styles.metaDataRow} ${styles.metaDataRowClickable}`}>
        <div className={styles.metaDataName}>
          Language
        </div>
        <div className={styles.metaDataValue}>
          {languageLabel || 'Unknown'}
        </div>
      </Link>
    </div>
      {(possibleRss || category === 'email') && (isSubscribed || emailIsSubscribed) && <DeleteFeedDialog
        isOpen={deleteFeedDialogOpen}
        onConfirm={() => {
          setDeleteFeedDialogOpen(false);
          if (possibleRss) {
            removeFeed(feedId, { userInteraction: 'sidebar-button-click' });
          } else {
            toggleEmailSubscription(emailAddress ? emailAddress : '', emailIsSubscribed, { userInteraction: 'sidebar-button-click' });
            removeDocsFromTheSameEmailAddress(emailAddress);
          }
          setDeleteFeedDialogOpen(false);
          leaveReadingViewIfOpen();
        }}
        onCancel={() => setDeleteFeedDialogOpen(false)}
        message={possibleRss ? messageCopy.removeFeedWarningText : emailSpecificWarningMessage}
        />}
  </div>;
};
const DocTitle = ({ doc }: { doc: AnyDocument; }): JSX.Element => {
  const [isHoveringContainer, setIsHoveringContainer] = useState(false);
  const [isHoveringButton, setIsHoveringButton] = useState(false);

  const showCopyButton = isHoveringContainer || isHoveringButton;
  const copyButtonClassName = [styles.copyButton, showCopyButton ? styles.visible : ''].join(' ');

  // We need this delay because the copy button is positioned absolute
  // and sometimes it's ouside the container. So using CSS :hover doesn't work.
  const setIsHoveringContainerWithDelay = () => {
    setTimeout(() => {
      setIsHoveringContainer(false);
    }, 300);
  };

  return (
    <div
      className={styles.articleTitle}
      onMouseEnter={() => setIsHoveringContainer(true)}
      onMouseLeave={setIsHoveringContainerWithDelay}
    >
      <span lang={doc.language}>
        {getDocumentTitle(doc)}
      </span>
      <span
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => setIsHoveringButton(false)}
      >
        <CopyButton
          className={copyButtonClassName}
          onClick={() => copyDocumentTitleOnWeb(doc)}
        />
      </span>
    </div>
  );
};
