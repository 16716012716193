import 'react-datepicker/dist/react-datepicker.css';

import React, { useCallback, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import createDate from '../../../shared/utils/dates/createDate';
import Button from './Button';
import styles from './DatePicker.module.css';
import CalendarIcon from './icons/20StrokeCalendar';

function DatePickerConfirmationButtons({ hasChanges, onCancel, onSave }: {
  hasChanges: boolean;
  onCancel: () => void;
  onSave: () => void;
}) {
  return <div className={styles.buttonContainer}>
    <div className={styles.buttons}>
      <Button className={styles.cancelButton} onClick={onCancel}>Cancel</Button>
      <Button className={hasChanges ? styles.blueButton : styles.disabledButton} onClick={onSave}>Set date</Button>
    </div>
  </div>;
}

export default function DatePicker({ onSaveDate, originalDate: originalDateArgument }: {onSaveDate: (date: Date) => void; originalDate: number | undefined;}): JSX.Element {
  const originalDate = useMemo(() => originalDateArgument ? new Date(originalDateArgument) : new Date(), [originalDateArgument]);
  const [focusedDate, setFocusedDate] = useState(originalDate);
  const hasChanges = useMemo(() => Boolean(focusedDate.getTime() !== originalDate.getTime()), [originalDate, focusedDate]);
  const [isOpen, setIsOpen] = useState(false);

  const onCancel = useCallback(() => {
    setIsOpen(false);
    setFocusedDate(originalDate);
  }, [originalDate]);

  const onSave = useCallback(() => {
    setIsOpen(false);
    onSaveDate(focusedDate);
  }, [onSaveDate, focusedDate]);

  const handleChangeRaw = useCallback((value) => {
    const dateTemplate = /^(19|20)\d\d-([1-9]|[0-1][0-9])-([1-9]|[12][0-9]|3[01])$/;
    if (dateTemplate.test(value)) {
      const inputtedDate = createDate(`${value} 00:00`);
      if (!isNaN(inputtedDate.getTime())) {
        setFocusedDate(inputtedDate);
      }
    }
  }, []);

  return <div style={{ position: 'relative' }}>
    <ReactDatePicker
      showIcon
      shouldCloseOnSelect={false}
      onChangeRaw={handleChangeRaw} // reading the input specifically from this prop is what allows the calendar to update live
      onInputClick={() => setIsOpen(true)}
      onClickOutside={onCancel}
      open={isOpen}
      dateFormat="yyyy-MM-dd"
      placeholderText="yyyy-mm-dd"
      selected={focusedDate}
      onChange={(date: Date | null) => {
        if (date === null) {
          setFocusedDate(originalDate);
        } else {
          setFocusedDate(date);
        }
      }}
      calendarClassName={styles.calendarWrapper}
    >
      <DatePickerConfirmationButtons hasChanges={hasChanges} onCancel={onCancel} onSave={onSave} />
    </ReactDatePicker>
    <span className={styles.calendarIcon}>
      <CalendarIcon />
    </span>
  </div>;
}
