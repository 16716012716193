import { AnyDocument } from '../types';
import getDocumentTitle from '../utils/getDocumentTitle';
import { createToast } from './toasts.platform';
import { copyTextToClipboard } from './utils/copyTextToClipboard';

export default (document: AnyDocument): void => {
  const title = getDocumentTitle(document);
  copyTextToClipboard(title);
  createToast({ content: 'Copied title to clipboard', category: 'success' });
};
