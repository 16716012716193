import React from 'react';

import Button from './Button';
import styles from './CopyButton.module.css';
import CopyIcon from './icons/14StrokeCopy';

export default function CopyButton({ onClick, className = '' }: { onClick: (event: MouseEvent) => void; className?: string; }): JSX.Element {
  return <Button variant="default" onClick={onClick} className={`${styles.copyButton} ${className}`}>
    <CopyIcon className={styles.copyIcon} />
  </Button>;
}
