import React from 'react';

import Icon from './Icon';

export default function ChevronRight({ className = '', text = 'right' }: { text?: string; className?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M7.96967 12.4697C7.67678 12.7626 7.67678 13.2374 7.96967 13.5303C8.26256 13.8232 8.73744 13.8232 9.03033 13.5303L12.0303 10.5303C12.3232 10.2374 12.3232 9.76256 12.0303 9.46967L9.03033 6.46967C8.73744 6.17678 8.26256 6.17678 7.96967 6.46967C7.67678 6.76256 7.67678 7.23744 7.96967 7.53033L10.4393 10L7.96967 12.4697Z" />
</svg>
  </Icon>;
}
