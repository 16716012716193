import React, { useCallback } from 'react';

import forwardRef from '../../../shared/foreground/utils/forwardRef';
import delay from '../../../shared/utils/delay';
import EditNoteForm from './EditNoteForm';
import styles from './NotebookNoteForm.module.css';

type Props = {
  className?: string;
  focusPseudoUnit: () => void;
  note?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onSave: (newNote: string) => void;
  placeholder?: string;
  shouldShowIfEmpty?: boolean;
} & Partial<React.HTMLAttributes<HTMLFormElement>>;

export default forwardRef<Props, HTMLFormElement>(function NotebookNoteForm ({
  className,
  focusPseudoUnit,
  note,
  onBlur,
  onFocus,
  onSave: onSaveArgument,
  placeholder,
  shouldShowIfEmpty,
  ...extraProps
}, ref) {
  const onSave = useCallback(async (newNote: string) => {
    onSaveArgument(newNote);
    await delay();
    focusPseudoUnit();
  }, [focusPseudoUnit, onSaveArgument]);

  return <EditNoteForm
    {...extraProps}
    className={[styles.noteForm, className].join(' ')}
    isFollowingFormFocused={false}
    isShownInMargin
    note={note}
    onBlur={onBlur}
    onCancel={async () => {
      await delay(10);
      focusPseudoUnit();
    }}
    onFocus={onFocus}
    onSave={onSave}
    placeholder={placeholder}
    ref={ref}
    shouldShowIfEmpty={shouldShowIfEmpty}
  />;
});
