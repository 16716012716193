import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useHighlightNoteText } from '../../../../shared/foreground/database/helperHooks';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState } from '../../../../shared/foreground/models';
import { useGlobalTagsAsObject, useIsPDFViewAsHTML } from '../../../../shared/foreground/stateHooks';
import { saveDocumentNote } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { deleteHighlight } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import { togglePDFHtmlViewForDocument } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/pdf';
import { setHighlightIdToOpenAt } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { setSeekYtPlayerTo } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/youtubePlayer';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import forwardRef from '../../../../shared/foreground/utils/forwardRef';
import getClosestHTMLElement from '../../../../shared/foreground/utils/getClosestHTMLElement';
import { getHighlightElement } from '../../../../shared/foreground/utils/getHighlightElements';
import getVisibilityDetails from '../../../../shared/foreground/utils/getVisibilityDetails';
import isImage from '../../../../shared/foreground/utils/isImage';
import useLiveValueRef from '../../../../shared/foreground/utils/useLiveValueRef';
import useRefSetter from '../../../../shared/foreground/utils/useRefSetter';
import useStatePlusLiveValueRef from '../../../../shared/foreground/utils/useStatePlusLiveValueRef';
import {
  type FirstClassDocument,
  type Highlight,
  type Note,
  type UserEvent,
  Category,
  ShortcutId,
} from '../../../../shared/types';
import type { GlobalTagsObject } from '../../../../shared/types/tags';
import { isDocumentWithUrl, isYouTubeUrl } from '../../../../shared/typeValidators';
import getNextItemInArray from '../../../../shared/utils/getNextItemInArray';
import { getTimeFromSeconds } from '../../../../shared/utils/getTimeFromSeconds';
import makeLogger from '../../../../shared/utils/makeLogger';
import { rwSanitizeHtml } from '../../../../shared/utils/rwSanitizeHtml';
import urlJoin from '../../../../shared/utils/urlJoin';
import { useHotKeys } from '../../hooks/hooks';
import escapeForHtml from '../../utils/escapeForHtml';
import focusFirstFocusableDescendant from '../../utils/focusFirstFocusableDescendant';
import { useShortcutsMap } from '../../utils/shortcuts';
import DocumentNoteForm from '../DocumentNoteForm';
import NotebookHighlightDropdown from '../Dropdown/NotebookHighlightDropdown';
import EditTagsForm from '../EditTagsForm';
import PlayIcon from '../icons/PlayIcon';
import NotebookHighlightNoteForm from '../NotebookHighlightNoteForm';
import {
  copyHighlightContent,
  openSingleParentNotebookView,
  shareHighlightAsImage,
} from '../NotebookView/notebookHelpers';
import Tooltip from '../Tooltip';
import styles from './RightSidebar.module.css';

const logger = makeLogger(__filename);

type PseudoFocusUnitProps = {
  render: (args: {
    borderElement: JSX.Element;
    focusPseudoUnit: () => void;
    props: React.HTMLProps<HTMLDivElement>;
  }) => JSX.Element;
};

const PseudoFocusUnit = forwardRef<PseudoFocusUnitProps, HTMLDivElement | null>(function PseudoFocusUnit({
  render,
}, ref) {
  const shortcutsMap = useShortcutsMap();
  const elementRef = useRef<HTMLElement | null>(null);
  const blur = useCallback(() => {
    if (!elementRef.current) {
      logger.warn('elementRef.current is undefined in blur()');
      return;
    }

    elementRef.current.blur();
  }, [elementRef]);
  const focus = useCallback(() => {
    if (!elementRef.current) {
      logger.warn('elementRef.current is undefined in focus()');
      return;
    }

    elementRef.current.focus();
  }, [elementRef]);

  const escapeShortcutRef = useHotKeys(
    shortcutsMap[ShortcutId.Esc],
    useCallback((event) => {
      if (!elementRef.current) {
        return;
      }
      event.stopPropagation();
      if (document.activeElement === elementRef.current) {
        blur();
      } else {
        focus();
      }
    }, [blur, focus, elementRef]),
    {
      hotKeyOptions: {
        enableOnFormTags: true,
      },
    },
  );

  const onFocus = useCallback(async () => {
    if (!elementRef.current) {
      return;
    }

    const scrollableRoot = elementRef.current.closest<HTMLElement>('.has-visible-scrollbar');
    if (!scrollableRoot) {
      throw new Error("Can't find scrollable ancestor");
    }

    const visibilityDetails = await getVisibilityDetails({
      subject: elementRef.current,
      scrollableRoot,
    });

    if (visibilityDetails.isCompletelyInView) {
      return;
    }

    elementRef.current.scrollIntoView({
      block: 'center',
    });
  }, []);

  return render({
    borderElement: <div className={styles.pseudoFocusUnitBorder} />,
    focusPseudoUnit: focus,
    props: {
      className: styles.pseudoFocusUnit,
      onFocus,
      ref: useRefSetter(elementRef, escapeShortcutRef, ref),
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex: 0,
    },
  });
});

const getSimpleHtmlFromNode = (root: HTMLElement) => {
  let html = '';
  const isTranscript = Boolean(root.dataset.rwStart ?? root.querySelector('span[data-rw-start]'));

  if (isTranscript) {
    html = '<span>';
    for (const childNode of root.childNodes) {
      const nodeTextContent = childNode.textContent?.trim() || '';
      html += ` ${nodeTextContent}`;
    }
    html += '</span>';

    return html;
  }

  for (const childNode of root.childNodes) {
    if (isImage(childNode)) {
      html += ` <img src="${(childNode as HTMLImageElement).src}" referrerpolicy="no-referrer"/>`;
    } else if (childNode.childNodes?.length) {
      html += ` ${getSimpleHtmlFromNode(childNode as HTMLElement)}`;
    } else {
      const nodeTextContent = childNode.textContent?.trim() || '';
      if (nodeTextContent) {
        html += `<span> ${nodeTextContent}</span>`;
      }
    }
  }

  return html;
};

export const NotebookHighlight = ({
  copyHighlightText,
  copyNote,
  deleteHighlight,
  doc,
  focusPseudoUnit,
  globalTagsObject,
  highlight,
  isReaderViewUrl,
  note,
  shareHighlight,
}: {
  copyHighlightText: () => void;
  copyNote: () => void;
  deleteHighlight: (userInteraction?: string) => void;
  doc: FirstClassDocument;
  focusPseudoUnit: () => void;
  globalTagsObject: GlobalTagsObject;
  highlight: Highlight;
  isReaderViewUrl: boolean;
  note?: Note['content'];
  shareHighlight: () => void;
}) => {
  const history = useHistory();
  const isPDF = doc.category === Category.PDF;
  const isPDFViewHighlight = Boolean(highlight.source_specific_data?.pdf_highlight);
  const isPDFViewAsHTML = useIsPDFViewAsHTML(doc.id);
  const isYouTube = isDocumentWithUrl(doc) && doc.url && isYouTubeUrl(doc.url);

  const editNoteFormRef = useRef<HTMLFormElement>(null);
  const editTagsFormRef = useRef<HTMLElement>(null);

  const highlightTimestampInSeconds = useMemo(() => {
    if (!isYouTube) {
      return;
    }


    const highlightElement = getHighlightElement({ container: document, id: highlight.id });

    if (!highlightElement) {
      return;
    }

    const firstStartElement = getClosestHTMLElement(
      highlightElement,
      (element) => typeof element.dataset.rwStart !== 'undefined',
    );

    if (firstStartElement?.dataset.rwStart) {
      return Number(firstStartElement.dataset.rwStart);
    }
  }, [isYouTube, highlight.id]);

  const highlightTimestampFormatted = useMemo(() => {
    if (highlightTimestampInSeconds) {
      return getTimeFromSeconds(highlightTimestampInSeconds);
    }
  }, [highlightTimestampInSeconds]);

  const highlightIdToOpenAtRef = useLiveValueRef(globalState(useCallback((state) => state.highlightIdToOpenAt, [])));

  const focusEditNoteForm = useCallback(() => {
    if (!editNoteFormRef.current) {
      return;
    }
    focusFirstFocusableDescendant(editNoteFormRef.current);
  }, []);

  const focusEditTagsForm = useCallback(() => {
    editTagsFormRef.current?.focus();
  }, []);

  const goToHighlight = useCallback(async () => {
    if (isReaderViewUrl) {
      if (isPDF) {
        // Check if this highlight is a PDF highlight or Text highlight
        await togglePDFHtmlViewForDocument(doc.id, !isPDFViewHighlight, 'click');
      }

      if (highlightTimestampInSeconds) {
        setSeekYtPlayerTo(highlightTimestampInSeconds);
      }

      /*
        If clicking the same highlight twice, we need to unset highlightIdToOpenAt and set it again so it the scroll
        will actually happen
      */
      let userEventCorrelationId: UserEvent['correlationId'] | undefined;
      if (highlight.id === highlightIdToOpenAtRef.current) {
        userEventCorrelationId = (await setHighlightIdToOpenAt(null, { userInteraction: 'click' }))?.userEvent?.id;
      }
      await setHighlightIdToOpenAt(highlight.id, {
        correlationId: userEventCorrelationId ?? null,
        userInteraction: 'click',
      });

      const highlightElement = getHighlightElement({ container: document.body, id: highlight.id });
      if (highlightElement) {
        eventEmitter.emit('update-content-focus-indicator-target', { target: highlightElement });
      }
    } else {
      history.push(urlJoin(['/read', highlight.id]));
    }
  }, [
    doc.id,
    highlight.id,
    highlightIdToOpenAtRef,
    highlightTimestampInSeconds,
    history,
    isPDF,
    isPDFViewHighlight,
    isReaderViewUrl,
  ]);

  const openInNotebookView = useCallback(() => {
    if (!highlight.parent) {
      throw new Error('Highlight has no parent document');
    }
    openSingleParentNotebookView(history, highlight.parent, highlight.id);
  }, [history, highlight]);

  useEffect(() => {
    eventEmitter.on(`focus-edit-note-form-in-notebook:${highlight.id}`, focusEditNoteForm);
    eventEmitter.on(`focus-edit-tags-form-in-notebook:${highlight.id}`, focusEditTagsForm);
    eventEmitter.on(`go-to-highlight:${highlight.id}`, goToHighlight);
    eventEmitter.on(`view-in-fullscreen-notebook:${highlight.id}`, openInNotebookView);
    return () => {
      eventEmitter.off(`focus-edit-note-form-in-notebook:${highlight.id}`, focusEditNoteForm);
      eventEmitter.off(`focus-edit-tags-form-in-notebook:${highlight.id}`, focusEditTagsForm);
      eventEmitter.off(`go-to-highlight:${highlight.id}`, goToHighlight);
      eventEmitter.off(`view-in-fullscreen-notebook:${highlight.id}`, openInNotebookView);
    };
  }, [openInNotebookView, focusEditNoteForm, focusEditTagsForm, goToHighlight, highlight.id]);

  const highlightHtml = useMemo(() => {
    const isTranscript = highlight.html.includes('data-rw-start=');
    const hasImage = highlight.html.includes('<img');

    if (hasImage || isTranscript) {
      const sanitizedHtml = rwSanitizeHtml(highlight.html, doc.category);
      const parser = new DOMParser();
      const parsedBody = parser.parseFromString(sanitizedHtml, 'text/html').body;

      return getSimpleHtmlFromNode(parsedBody);
    }

    return `<span>${escapeForHtml(highlight.content || '')}</span>`;
  }, [doc.category, highlight.html, highlight.content]);

  const highlightOnlyVisibleInTextView = isPDF && !isPDFViewHighlight && !isPDFViewAsHTML;
  const highlightOnlyVisibleInPDFView = isPDF && isPDFViewHighlight && isPDFViewAsHTML;
  let tooltipText = '';
  if (highlightOnlyVisibleInTextView) {
    tooltipText = 'Highlight only visible in text view';
  }
  if (highlightOnlyVisibleInPDFView) {
    tooltipText = 'Highlight only visible in PDF view';
  }

  return <Tooltip content={tooltipText} disabled={!highlightOnlyVisibleInPDFView && !highlightOnlyVisibleInTextView}>
    <div
      className={`${styles.highlight} ${highlightOnlyVisibleInTextView || highlightOnlyVisibleInPDFView ? styles.fadeHighlight : ''}`}
      data-highlight-id={highlight.id}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.highlightContentWrapper}
        onClick={goToHighlight}
      >
        {highlightTimestampFormatted && <span className={styles.highlightTimestamp}>
          <PlayIcon text="Timestamp:" /> {highlightTimestampFormatted}
        </span>}
        <span
          dangerouslySetInnerHTML={{
            __html: highlightHtml,
          }} />
      </div>
      <EditTagsForm
        className={styles.editTagsForm}
        doc={highlight}
        globalTagsObject={globalTagsObject}
        isShownInMargin
        ref={editTagsFormRef}
        shouldShowIfEmpty={false}
        tabIndex={-1}
      />
      <NotebookHighlightNoteForm
        className={styles.editNoteForm}
        focusPseudoUnit={focusPseudoUnit}
        highlight={highlight}
        note={note}
        ref={editNoteFormRef}
      />
      <NotebookHighlightDropdown
        buttonClassName={styles.highlightMenuButton}
        copyHighlightText={copyHighlightText}
        copyNote={copyNote}
        shareHighlight={shareHighlight}
        deleteHighlight={deleteHighlight}
        openEditNoteForm={focusEditNoteForm}
        openEditTagsForm={focusEditTagsForm}
        openInNotebookView={openInNotebookView}
        focusPseudoUnit={focusPseudoUnit}
        goToHighlight={goToHighlight}
        highlight={highlight}
        note={note}
      />
    </div>
  </Tooltip>;
};
const HighlightUnit = ({
  doc,
  globalTagsObject,
  highlight,
  isReaderViewUrl,
}: {
  doc: FirstClassDocument;
  globalTagsObject: GlobalTagsObject;
  highlight: Highlight;
  isReaderViewUrl: boolean;
}) => {
  const note = useHighlightNoteText(highlight);

  // TODO: remove?
  const elementRef = useRef<HTMLDivElement | null>(null);
  const shortcutsMap = useShortcutsMap();

  const copyHighlightTextShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.CopyNotebookHighlightText],
    useCallback((event) => {
      event.stopPropagation();
      copyHighlightContent(highlight.content);
    }, [highlight]),
    {
      description: 'Copy highlight text',
    },
  );

  const copyNoteShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.CopyNotebookHighlightNote],
    useCallback((event) => {
      event.stopPropagation();
      copyHighlightContent(note);
    }, [note]),
    {
      description: 'Copy highlight note',
    },
  );

  const deleteShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.DeleteHighlightInNotebook],
    useCallback((event) => {
      event.stopPropagation();
      deleteHighlight(highlight.id, { userInteraction: 'unknown' });
    }, [highlight]),
    {
      description: 'Delete highlight',
    },
  );

  const editNoteShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.EditHighlightNoteInNotebook],
    useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
      eventEmitter.emit(`focus-edit-note-form-in-notebook:${highlight.id}`);
    }, [highlight.id]),
    {
      description: 'Edit highlight note',
    },
  );

  const editTagsShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.EditHighlightTagsInNotebook],
    useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
      eventEmitter.emit(`focus-edit-tags-form-in-notebook:${highlight.id}`);
    }, [highlight.id]),
    {
      description: 'Edit highlight tags',
    },
  );

  const highlightMenuShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.OpenDocumentNoteActionsDropdown],
    useCallback((event) => {
      event.stopPropagation();
      eventEmitter.emit(`set-highlight-menu-open:${highlight.id}`, true);
    }, [highlight.id]),
    {
      description: 'Open highlight actions dropdown',
    },
  );

  const viewInDocumentShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.ViewNotebookHighlightInDocument],
    useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
      eventEmitter.emit(`go-to-highlight:${highlight.id}`);
    }, [highlight.id]),
    {
      description: 'View highlight in document',
    },
  );

  const openInNotebookShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.ViewHighlightInNotebookView],
    useCallback((event) => {
      event.preventDefault();
      eventEmitter.emit(`view-in-fullscreen-notebook:${highlight.id}`);
    }, [highlight.id]),
    {
      description: 'View highlight in notebook',
    },
  );

  return <PseudoFocusUnit
    render={({ borderElement, focusPseudoUnit, props }) => {
      return <div
        {...props}
        className={[props.className, styles.highlightPseudoFocusUnit].filter(Boolean).join(' ')}>
        <NotebookHighlight
          copyHighlightText={() => copyHighlightContent(highlight.content)}
          copyNote={() => copyHighlightContent(note)}
          deleteHighlight={() => deleteHighlight(highlight.id, { userInteraction: 'unknown' })}
          doc={doc}
          focusPseudoUnit={focusPseudoUnit}
          globalTagsObject={globalTagsObject}
          highlight={highlight}
          isReaderViewUrl={isReaderViewUrl}
          note={note}
          shareHighlight={() => shareHighlightAsImage(highlight)}
        />
        {borderElement}
      </div>;
    }}
    ref={useRefSetter(
      elementRef,
      copyHighlightTextShortcutRef,
      copyNoteShortcutRef,
      deleteShortcutRef,
      editNoteShortcutRef,
      editTagsShortcutRef,
      highlightMenuShortcutRef,
      viewInDocumentShortcutRef,
      openInNotebookShortcutRef,
    )}
  />;
};

export const DocumentNotebookPanel = React.memo(function DocumentNotebookPanel({
  doc,
  highlights,
  isReaderViewUrl,
  sidebarsHidden,
}: {
  doc: FirstClassDocument;
  highlights: Highlight[];
  isReaderViewUrl: boolean;
  sidebarsHidden: boolean;
}) {
  const [globalTagsObject] = useGlobalTagsAsObject();

  const [rootElement, setRootElement, rootElementRef] = useStatePlusLiveValueRef<HTMLDivElement | null>(null);
  const doesElementExist = useMemo(() => Boolean(rootElement), [rootElement]);
  useEffect(() => {
    if (sidebarsHidden || !doesElementExist || !rootElementRef.current) {
      return;
    }

    // do not switch focus if there is already an active input field (e.g. search)
    if (document.activeElement?.tagName === 'INPUT') {
      return;
    }

    focusFirstFocusableDescendant(rootElementRef.current);
  }, [doesElementExist, rootElementRef, sidebarsHidden]);

  const shortcutsMap = useShortcutsMap();

  const moveFocusToNextPseudoFocusUnit = ({
    activeElement,
    direction,
    rootElement,
  }: {
    activeElement: Element;
    direction?: string;
    rootElement: HTMLElement;
  }) => {
    const pseudoFocusUnitSelector = `.${styles.pseudoFocusUnit}`;
    const pseudoFocusUnits = Array.from(
      rootElement.querySelectorAll<HTMLDivElement>(pseudoFocusUnitSelector),
    );
    if (!pseudoFocusUnits.length) {
      return;
    }

    const currentPseudoFocusUnit = activeElement.closest<HTMLDivElement>(pseudoFocusUnitSelector);
    const pseudoFocusUnitToFocus = getNextItemInArray({
      currentIndex: currentPseudoFocusUnit ? pseudoFocusUnits.indexOf(currentPseudoFocusUnit) : -1,
      direction,
      list: pseudoFocusUnits,
      shouldWrapAround: false,
    });

    pseudoFocusUnitToFocus?.focus();
  };

  useHotKeys(
    // TODO: use shortcutsMap?
    [ShortcutId.Down],
    useCallback(async (event) => {
      if (!rootElementRef.current ||
        !document.activeElement ||
        !rootElementRef.current.contains(document.activeElement)) {
        return;
      }
      event.preventDefault();

      moveFocusToNextPseudoFocusUnit({
        activeElement: document.activeElement,
        rootElement: rootElementRef.current,
      });
    }, [rootElementRef]),
    {
      description: 'Move focus to next notebook item',
    },
  );

  useHotKeys(
    // TODO: use shortcutsMap?
    [ShortcutId.Up],
    useCallback(async (event) => {
      if (!rootElementRef.current ||
        !document.activeElement ||
        !rootElementRef.current.contains(document.activeElement)) {
        return;
      }
      event.preventDefault();

      moveFocusToNextPseudoFocusUnit({
        activeElement: document.activeElement,
        direction: 'backward',
        rootElement: rootElementRef.current,
      });
    }, [rootElementRef]),
    {
      description: 'Move focus to previous notebook item',
    },
  );

  const copyDocumentNote = useCallback(() => {
    if (!doc.notes) {
      return;
    }
    navigator.clipboard.writeText(doc.notes);
    createToast({
      content: 'Copied to clipboard',
      category: 'success',
    });
  }, [doc.notes]);

  const deleteDocumentNote = useCallback(async () => {
    if (!doc.notes) {
      return;
    }
    const result = await saveDocumentNote(doc.id, '', { userInteraction: 'unknown' });
    createToast({
      content: 'Note deleted',
      category: 'success',
      undoableUserEventId: result.userEvent?.id,
    });
  }, [doc.id, doc.notes]);

  const documentNotePseudoFocusUnitRef = useRef<HTMLDivElement | null>(null);

  const documentNoteMenuShortcutRef = useHotKeys(
    shortcutsMap[ShortcutId.OpenNotebookHighlightActionsDropdown],
    useCallback((event) => {
      event.stopPropagation();
      eventEmitter.emit('set-doc-note-is-open', true);
    }, []),
    {
      description: 'Open document note actions dropdown',
    },
  );

  const copyDocumentNoteShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.CopyDocumentNote],
    useCallback((event) => {
      event.stopPropagation();
      copyDocumentNote();
    }, [copyDocumentNote]),
    {
      description: 'Copy document note',
    },
  );

  const deleteDocumentNoteShortcutRef = useHotKeys<HTMLDivElement>(
    shortcutsMap[ShortcutId.DeleteDocumentNote],
    useCallback((event) => {
      event.stopPropagation();
      deleteDocumentNote();
    }, [deleteDocumentNote]),
    {
      description: 'Delete document note',
    },
  );

  const enterShortcutRef = useHotKeys(
    shortcutsMap[ShortcutId.Enter],
    useCallback((event) => {
      if (!documentNotePseudoFocusUnitRef.current) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();
      focusFirstFocusableDescendant(documentNotePseudoFocusUnitRef.current);
    }, [documentNotePseudoFocusUnitRef]),
    {
      description: 'Focus document note field',
    },
  );

  const documentNoteTextareaRef = useRef<HTMLTextAreaElement | null>(null);

  return <div
    className={`${styles.content} ${styles.notebookPanel} ${sidebarsHidden ? styles.hidden : ''}`}
    // Used by JS elsewhere
    id="notebook-sidebar-panel"
    ref={setRootElement}>

    <h3 className={[styles.title, styles.documentNoteTitle].join(' ')}>Document note</h3>
    <PseudoFocusUnit
      render={({ borderElement, focusPseudoUnit, props }) => {
        return <div
          {...props}
          className={[props.className, styles.documentNotePseudoFocusUnit].filter(Boolean).join(' ')}>
          <DocumentNoteForm
            className={styles.documentNoteForm}
            copyNote={copyDocumentNote}
            deleteNote={deleteDocumentNote}
            doc={doc}
            focusPseudoUnit={focusPseudoUnit}
            ref={documentNoteTextareaRef}
          />
          {borderElement}
        </div>;
      }}
      ref={useRefSetter(
        copyDocumentNoteShortcutRef,
        deleteDocumentNoteShortcutRef,
        documentNoteMenuShortcutRef,
        documentNotePseudoFocusUnitRef,
        enterShortcutRef,
      )}
    />

    <div className={styles.higlightsContainer}>
      <h3 className={styles.title}>Highlights</h3>
      {highlights.length > 0 ? sortBy(highlights, (h) => h.offset).map((h, index) => {
        const isLast = index === highlights.length - 1;

        return (
          <React.Fragment key={h.id}>
            <HighlightUnit
              doc={doc}
              globalTagsObject={globalTagsObject}
              highlight={h}
              isReaderViewUrl={isReaderViewUrl}
            />
            {!isLast && <div className={styles.innerContentDivider} />}
          </React.Fragment>
        );
      }) : <div className={styles.emptyHighlights}>
            <p>Highlights will appear here</p>
        </div>}
    </div>
  </div>;
});
