import React, { useCallback } from 'react';

import { deleteHighlightNote, updateHighlightNote } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import forwardRef from '../../../shared/foreground/utils/forwardRef';
import { Highlight } from '../../../shared/types';
import NotebookNoteForm from './NotebookNoteForm';

type Props = {
  className?: string;
  focusPseudoUnit: () => void;
  highlight: Highlight;
  note?: string;
};

export default forwardRef<Props, HTMLFormElement>(function NotebookHighlightNoteForm ({
  className,
  focusPseudoUnit,
  highlight,
  note,
  ...otherProps
}, ref) {
  const updateNote = useCallback((newNote: string) => {
    if (!highlight) {
      return;
    }

    const userInteraction = 'unknown';

    if (!newNote) {
      if (!highlight.children.length) {
        return;
      }
      deleteHighlightNote(highlight.children[0], {
        userInteraction,
      });
      return;
    }

    updateHighlightNote(
      highlight,
      newNote,
      {
        userInteraction,
      },
    );
  }, [highlight]);

  return <NotebookNoteForm
    {...otherProps}
    className={className}
    focusPseudoUnit={focusPseudoUnit}
    note={note}
    onSave={updateNote}
    ref={ref}
    shouldShowIfEmpty={false}
  />;
});
