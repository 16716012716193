import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { scrollToAnchor } from '../../../../shared/foreground/contentFramePortalGateInternalMethods';
import { useDocument, useFaviconUrlFromDoc, useRssSourceNameForDoc } from '../../../../shared/foreground/stateHooks';
import { saveLinkToReader } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { loadDocumentLinks, setNewLinkLoading } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/links';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import { Category, ContentParsingStatus, DocumentId, DocumentLink, DocumentWithUrl } from '../../../../shared/types';
import getDocumentDomain from '../../../../shared/utils/getDocumentDomain';
import getUrlDomain from '../../../../shared/utils/getUrlDomain';
import makeLogger from '../../../../shared/utils/makeLogger';
import urlJoin from '../../../../shared/utils/urlJoin';
import { normalizeUrl } from '../../../../shared/utils/urls';
import DocumentFaviconOrIcon from '../DocumentFaviconOrIcon';
import ChevronDown from '../icons/20StrokeChevronDownSmall';
import ChevronRight from '../icons/20StrokeChevronRightSmall';
import SolidCircleCheck from '../icons/SolidCircleCheck';
import StrokeAddCircleIcon from '../icons/StrokeAddCircleIcon';
import StrokeScrollIcon from '../icons/StrokeScrollIcon';
import TimeLeftToRead from '../TimeLeftToRead';
import Tooltip from '../Tooltip';
import type { DocumentPanelProps } from './RightSidebar';
import styles from './RightSidebar.module.css';

const logger = makeLogger(__filename);


function partitionLinks(links?: DocumentLink[]): {
  moreLinks: DocumentLink[];
  backlinks: DocumentLink[];
  forwardLinks: DocumentLink[];
  suggestedLinks: DocumentLink[];
} {
  const backlinks: DocumentLink[] = [];
  const forwardLinks: DocumentLink[] = [];
  const suggestedLinks: DocumentLink[] = [];
  const moreLinks: DocumentLink[] = [];
  if (links === undefined) {
    return { backlinks, forwardLinks, suggestedLinks, moreLinks };
  }
  for (const link of links) {
    if (link.kind === 'backlink') { // backlink in my library
      backlinks.push(link);
    } else if (link.other_document) { // forward link in my library
      forwardLinks.push(link);
    } else if (link.metadata) { // in Reader but not in my library
      suggestedLinks.push(link);
    } else {
      moreLinks.push(link); // not in Reader
    }
  }
  for (const linksSection of [forwardLinks, suggestedLinks, moreLinks]) {
    // order forward links by their position in HTML
    linksSection.sort((lhs, rhs) =>
      lhs.source_line === rhs.source_line
        ? lhs.source_col < rhs.source_col ? -1 : 1
        : lhs.source_line - rhs.source_line);
  }
  return { backlinks, forwardLinks, suggestedLinks, moreLinks };
}

function trailingContext(contextHtml: string): string {
  const anchorStart = contextHtml.indexOf('<a>');
  return contextHtml.substring(anchorStart);
}

const makeScrollToAnchorUri = (docId: string, url: string, anchorText: string) =>
  urlJoin([
    '/read',
    `${docId}?scroll=anchor&url=${encodeURIComponent(normalizeUrl(url))}&anchorText=${encodeURIComponent(anchorText)}`,
  ]);

const extractAnchorText = (link: DocumentLink) => {
  const anchorText = /<a>(.*)<\/a>/.exec(link.context_html)?.[1];
  if (!anchorText) {
    return '';
  }
  if (anchorText === '[Image]') {
    return '';
  }
  return anchorText;
};
const ScrollToLinkLocationButton = React.memo(function ScrollToLinkLocationButton({ link, docId }: {
  link: DocumentLink;
  docId: DocumentId;
}) {
  const history = useHistory();
  const containingDocumentIsOpen = history.location.pathname.indexOf(docId) !== -1;
  const scrollToLinkLocation = useCallback(async (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    const anchorText = extractAnchorText(link);
    const keyboardEvent = event as React.KeyboardEvent;
    if (keyboardEvent.key && keyboardEvent.key !== 'Enter') {
      return;
    }
    const url = link.url;
    if (!containingDocumentIsOpen) {
      history.push(makeScrollToAnchorUri(docId, url, anchorText));
    } else {
      scrollToAnchor({ url, anchorText });
    }
  }, [link, history, docId, containingDocumentIsOpen]);

  return (
    <Tooltip content={containingDocumentIsOpen ? 'Scroll to link location' : 'Open at link location'}>
      <div
        className={styles.linkAction}
        onKeyPress={scrollToLinkLocation}
        onClick={scrollToLinkLocation}>
        <StrokeScrollIcon />
      </div>
    </Tooltip>
  );
});
const SaveLinkToLibraryButton = React.memo(function SaveLinkToLibraryButton({ link, docId }: {
  link: DocumentLink;
  docId: DocumentId;
}) {
  const history = useHistory();

  const newDocumentLocation = useDocumentLocations()[0];

  const goToNewDoc = useCallback((docId: string) =>
    history.push(urlJoin(['/', newDocumentLocation, 'read', docId])), [newDocumentLocation, history]);

  const saveLinkToLibrary = useCallback(async (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();

    const keyboardEvent = event as React.KeyboardEvent;
    if (keyboardEvent.key && keyboardEvent.key !== 'Enter') {
      return;
    }
    const newDocId = await saveLinkToReader({
      newDocumentLocation,
      onButtonClick: goToNewDoc,
      url: link.url,
    });
    const userInteraction = event.type === 'click' ? 'click' : 'keypress';
    await setNewLinkLoading({ docId, url: link.url, newDocId, userInteraction });
  }, [link, docId, goToNewDoc, newDocumentLocation]);
  return (
    <Tooltip content="Save link to Reader">
      <div
        className={styles.linkAction}
        onKeyPress={saveLinkToLibrary}
        onClick={saveLinkToLibrary}>
        <StrokeAddCircleIcon />
      </div>
    </Tooltip>
  );
});
const CardContentWithMetadata = React.memo(function CardContentWithMetadata({ link, docId }: {
  link: DocumentLink;
  docId: DocumentId;
}) {
  const [otherDocument] = useDocument(link.other_document);
  const faviconUrl = useFaviconUrlFromDoc(otherDocument) ?? link.metadata?.favicon_url;
  const nameOrDomain = getDocumentDomain({
    rssSourceName: useRssSourceNameForDoc(otherDocument),
    originUrl: link.url ? getUrlDomain(link.url) : undefined,
    siteName: otherDocument?.site_name,
  });
  const readingPercent = otherDocument?.readingPosition?.scrollDepth ? otherDocument?.readingPosition.scrollDepth * 100 : 0;
  if (!link.metadata) {
    return null; // should never happen
  }

  return (
    <div className={`${styles.linkCard} ${styles.withMetadata}`}>
      <div className={styles.linkBody}>
        <div className={styles.linkHeader}>
          <DocumentFaviconOrIcon
            faviconUrl={faviconUrl}
            category={otherDocument?.category ?? Category.Article}
          />
          <div className={`${styles.domain} ${styles.overflowTextOnHover}`}>
            {nameOrDomain}
          </div>
          <div className={styles.linkActions}>
            {link.other_document
              ? <>
                {link.kind === 'forward' &&
                    <div className={styles.showOnHover}>
                        <ScrollToLinkLocationButton link={link} docId={docId} />
                    </div>}
                <Tooltip content="This document is in your library">
                  <div>
                    <SolidCircleCheck className={`${styles.linkAction} ${styles.inMyLibraryIcon}`} />
                  </div>
                </Tooltip>
              </>
              : <div className={styles.showOnHover}>
                <ScrollToLinkLocationButton link={link} docId={docId} />
                <SaveLinkToLibraryButton link={link} docId={docId} />
              </div>}
          </div>
        </div>
        <div className={styles.title}>
          {link.metadata.title}
        </div>
        <div
          className={styles.context}
          dangerouslySetInnerHTML={{ __html: link.kind === 'forward' ? link.metadata.summary : link.context_html }} />
      </div>
      <div className={styles.linkFooter}>
        <div className={styles.author}>
          {link.metadata.author}
        </div>
        {otherDocument && link.metadata?.word_count > 0 &&
            <TimeLeftToRead readingPercent={readingPercent} wordCount={link.metadata.word_count} />}
      </div>
    </div>
  );
});
const CardContentWithoutMetadata = React.memo(function CardContentWithoutMetadata({ link, docId }: {
  link: DocumentLink;
  docId: DocumentId;
}) {
  return <div className={`${styles.linkCard} ${styles.withoutMetadata}`}>
    <div className={styles.linkBody}>
      <div
        className={`${styles.context} ${styles.overflowTextOnHover}`}
        dangerouslySetInnerHTML={{ __html: trailingContext(link.context_html) }} />
      <div className={`${styles.linkUrl} ${styles.overflowTextOnHover}`}>
        {link.url}
      </div>
    </div>
    <div className={styles.linkActions}>
      <div className={styles.showOnHover}>
        <ScrollToLinkLocationButton link={link} docId={docId} />
        <SaveLinkToLibraryButton link={link} docId={docId} />
      </div>
    </div>
  </div>;
});
const LinkCard = React.memo(function LinkCard({ link, docId }: { link: DocumentLink; docId: DocumentId; }) {
  const CardContent = link.metadata ? CardContentWithMetadata : CardContentWithoutMetadata;
  const [document] = useDocument<DocumentWithUrl>(docId);
  let documentLinkUri;
  if (link.other_document) {
    if (link.kind === 'backlink' && document) {
      documentLinkUri = makeScrollToAnchorUri(link.other_document, document.url, extractAnchorText(link));
    } else {
      documentLinkUri = urlJoin(['/read', link.other_document]);
    }
  }
  return documentLinkUri
    ? <Link to={documentLinkUri}>
      <CardContent link={link} docId={docId} />
    </Link>
    : <a href={link.url} target="_blank" rel="noreferrer">
      <CardContent link={link} docId={docId} />
    </a>;
});
const LinksSection = React.memo(function LinksSection({ links, title, docId }: {
  links: DocumentLink[];
  title: string;
  docId: DocumentId;
}) {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => setExpanded(!expanded);

  if (links.length === 0) {
    return null;
  }
  const linkCards = expanded && links.map((link) => <LinkCard key={link.url} link={link} docId={docId} />);
  return <>
    <div
      className={`${styles.linkSectionHeading} ${styles.clickable}`}
      onKeyPress={toggleExpanded}
      onClick={toggleExpanded}>
      <span>{title}</span>
      {expanded ? <ChevronDown /> : <ChevronRight />}
    </div>
    {linkCards}
  </>;
});
const LINKS_PER_SECTION_LIMIT = 100;
export const DocumentLinksPanel = React.memo(function DocumentLinksPanel({
  document: {
    id: docId,
    parsed_doc_id: maybeParsedDocId,
    transientData: {
      contentParsingStatus,
      links,
    },
  },
  sidebarsHidden,
}: DocumentPanelProps) {
  const prevParsingStatus = useRef(contentParsingStatus);
  useEffect(() => {
    const parsedDocId = maybeParsedDocId?.toString();
    if (parsedDocId === undefined) {
      logger.debug('Cant load document links, no parsed doc ID', { docId });
      return;
    }
    // We force reload Links from server when content parsing status changes from pending -> success,
    // or when a linked document was recently deleted (and links were set to undefined).
    const shouldBypassCache =
      [ContentParsingStatus.Pending, ContentParsingStatus.ServerTaskNotStartedYet].includes(prevParsingStatus.current) &&
      contentParsingStatus === ContentParsingStatus.Success ||
      links === undefined;
    loadDocumentLinks({
      parsedDocIds: [parsedDocId],
      shouldBypassCache,
    });
    prevParsingStatus.current = contentParsingStatus;
  }, [links, docId, maybeParsedDocId, contentParsingStatus]);
  const { backlinks, forwardLinks, suggestedLinks, moreLinks } =
    useMemo(() => partitionLinks(links), [links]);
  if (!maybeParsedDocId) {
    return <div>No links</div>;
  }
  if (links === undefined ||
    [ContentParsingStatus.Pending, ContentParsingStatus.ServerTaskNotStartedYet].includes(contentParsingStatus)) {
    return <em>Loading links..</em>;
  }
  if (links.length === 0) {
    return <div>No links</div>;
  }
  for (const sectionLinks of [backlinks, forwardLinks, suggestedLinks, moreLinks]) {
    sectionLinks.splice(LINKS_PER_SECTION_LIMIT);
  }

  return (
    <div className={`${styles.content} ${sidebarsHidden ? styles.hidden : ''}`}>
      {backlinks.length > 0 || forwardLinks.length > 0
        ? <div className={styles.linkSectionHeading}>INSIDE YOUR LIBRARY</div> : null}
      <LinksSection links={backlinks} title="Backlinks" docId={docId} />
      <LinksSection links={forwardLinks} title="Forward links" docId={docId} />

      {suggestedLinks.length > 0 || moreLinks.length > 0
        ? <div className={styles.linkSectionHeading}>OUTSIDE YOUR LIBRARY</div> : null}
      <LinksSection links={suggestedLinks} title="Suggested links" docId={docId} />
      <LinksSection links={moreLinks} title="More links" docId={docId} />
    </div>
  );
});
