import React from 'react';

import { Category } from '../../../../shared/types';
import styles from './NotebookCoverImage.module.css';
import usePlaceholderImage from './usePlaceholderImage';

export const NotebookCoverImage = ({ imageUrl, category }: {imageUrl: string | undefined; category: Category;}): JSX.Element => {
  const classes = [category === Category.EPUB ? styles.notebookEpubImage : styles.notebookPreviewImage];

  const hasImageUrl = Boolean(imageUrl);
  if (!imageUrl) {
    classes.push(styles.notebookPlaceholderImage);
  }

  const { placeholderIcon, placeholderBackgroundImage } = usePlaceholderImage(category, hasImageUrl);

  return <div
    className={classes.join(' ')}
    style={{ backgroundImage: `url(${imageUrl}), url("${placeholderBackgroundImage}")` }}>
    {placeholderIcon}
  </div>;
};
