import Fuse from 'fuse.js';
import map from 'lodash/map';

function FuseSearch<Type>(items: Type[], searchValue: string, keys: string[] = [], config: Fuse.IFuseOptions<Type> = {}) {
  if (searchValue && searchValue !== '') {
    const fuse = new Fuse(items, {
      distance: 500,
      keys,
      location: 0,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      shouldSort: true,
      threshold: 0.2,
      ...config,
    } as Fuse.IFuseOptions<Type>);
    return Array.from(map(fuse.search(searchValue), 'item'));
  }
  return items;
}

export default FuseSearch;
