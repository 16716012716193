import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ANDROID_APP_URL, IOS_APP_URL } from '../../../../shared/constants.platform';
import { openFeedsSubMenu } from '../../../../shared/foreground/cmdPalette';
import copyTextOnWeb from '../../../../shared/foreground/copyTextOnWeb';
import { globalState } from '../../../../shared/foreground/models';
import { setEmptyStateCategory } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { DisplayTheme } from '../../../../shared/types';
import getServerBaseUrl from '../../../../shared/utils/getServerBaseUrl.platform';
import requestWithAuth from '../../../../shared/utils/requestWithAuth.platformIncludingExtension';
import Button from '../Button';
import { FileUploadContext, maxFileSizeMb } from '../FileDropzone';
import AndroidIcon from '../icons/AndroidIcon';
import AppleIcon from '../icons/AppleIcon';
import ChromeIcon from '../icons/ChromeIcon';
import FirefoxIcon from '../icons/FirefoxIcon';
import SafariIcon from '../icons/SafariIcon';
import StrokeCancelIcon from '../icons/StrokeCancelIcon';
import Tooltip from '../Tooltip';
import styles from './RightSidebar.module.css';

const BrowserExtensions = () => {
  return (
    <div className={styles.emptyBlock}>
      <h3>Browser extensions</h3>
      <p>Install an extension to save articles to your Reader account and to optionally highlight the native web.</p>
      <div className={styles.buttons}>
        <a href="https://chrome.google.com/webstore/detail/jjhefcfhmnkfeepcpnilbbkaadhngkbi" target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <ChromeIcon text="" />
          Chrome
        </a>
        <a href="https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter/" target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <FirefoxIcon text="" />
          Firefox
        </a>
        <a href="https://apps.apple.com/app/save-to-reader/id1640236961" target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <SafariIcon className={styles.safariIcon} text="" />
          Safari
        </a>
      </div>
    </div>
  );
};

const MobileApps = () => {
  return (
    <div className={styles.emptyBlock}>
      <h3>Mobile apps</h3>
      <p>Install the native mobile apps to read on and save documents from your Android and iOS devices.</p>
      <div className={styles.buttons}>
        <a href={ANDROID_APP_URL} target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <AndroidIcon />
          Android
        </a>
        <a href={IOS_APP_URL} target="_blank" rel="noopener noreferrer" className={`${styles.primary}`}>
          <AppleIcon />
          iOS
        </a>
      </div>
    </div>
  );
};

export const EmptyStateSidebarContent = ({ isEmptyLibraryCategory = false, isEmptyPdfsCategory = false, isEmptyEpubsCategory = false, isEmptyFeedCategory = false, isEmptyArticlesCategory = false, isEmptyTweetsCategory = false, isEmptyEmailsCategory = false }) => {
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const { openFileDialog } = useContext(FileUploadContext);

  const [emailReminderSent, setEmailReminderSent] = useState(false);

  const theme = globalState(useCallback((state) => state.client.theme, []));

  const emptyStateImgSrc = useMemo(() => {
    const themeType = theme === DisplayTheme.Default ? 'light' : 'dark';

    if (isEmptyArticlesCategory) {
      return `/empty-sidebar-browser-${themeType}.png`;
    }

    if (isEmptyEmailsCategory) {
      return `/empty-sidebar-email-${themeType}.png`;
    }

    if (isEmptyTweetsCategory) {
      return `/empty-sidebar-twitter-${themeType}.png`;
    }

    if (isEmptyPdfsCategory) {
      return `/empty-sidebar-pdf-${themeType}.png`;
    }

    if (isEmptyEpubsCategory) {
      return `/empty-sidebar-epub-${themeType}.png`;
    }

    return '';
  }, [theme, isEmptyPdfsCategory, isEmptyEpubsCategory, isEmptyArticlesCategory, isEmptyTweetsCategory, isEmptyEmailsCategory]);

  if (isEmptyTweetsCategory) {
    return (
      <div className={`${styles.emptyStateContent} ${styles.isEmptyTweetsCategory}`}>
        <img alt="upload" src={emptyStateImgSrc} />
        <div className={styles.emptyBlock}>
          <h3>Compile threads</h3>
          <p>Compile Twitter threads into legible documents by either publicly replying “@readwise save thread” or privately DMing the tweet to @readwise with the message “t” or “thread”.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Connect your Twitter account</h3>
          <p>To begin saving Twitter threads, <a href="https://readwise.io/twitter_start" target="_blank" rel="noopener noreferrer" className={styles.link}>connect your account</a> in Readwise.</p>
        </div>
      </div>
    );
  }

  if (isEmptyEmailsCategory) {
    return (
      <div className={`${styles.emptyStateContent} ${styles.isEmptyEmailsCategory}`}>
        <img alt="upload" src={emptyStateImgSrc} />
        <div className={styles.emptyBlock}>
          <h3>Forward emails</h3>
          <p>You can forward document-like emails to your Library using your custom Reader email address. Note that you have two emails: one for Library and one for Feed.</p>
          <div className={styles.copyEmailSection}>
            <span className={styles.emailAddress}>{profile?.custom_library_email}</span>
            <button className={styles.copyEmailButton} type="button" onClick={() => copyTextOnWeb(profile?.custom_library_email || '')}>Copy</button>
          </div>
          <p>
            <button
              className={`${styles.sendReminderButton} ${styles.link}`}
              type="button"
              onClick={() => {
                if (emailReminderSent) {
                  return;
                }
                setEmailReminderSent(true);
                createToast({
                  content: 'Email reminder sent!',
                  category: 'success',
                });
                requestWithAuth(
                  `${getServerBaseUrl()}/reader/api/send_custom_email_onboarding/`,
                  {
                    method: 'POST',
                    credentials: 'include',
                    mode: 'cors',
                  },
                );
              }}>
              Send yourself a reminder email
            </button> to effortlessly add your custom Reader addresses to your contact list.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email newsletters</h3>
          <p>We generally recommend that you direct email newsletters to your Feed, but you can send them to your Library instead using the address above.</p>
        </div>
      </div>
    );
  }

  if (isEmptyArticlesCategory) {
    return (
      <div className={`${styles.emptyStateContent} ${styles.isEmptyArticlesCategory}`}>
        <img alt="upload" src={emptyStateImgSrc} />
        <BrowserExtensions />
        <MobileApps />
        <div className={styles.emptyBlock}>
          <h3>Integrations</h3>
          <p>Integrate Readwise with <a href="https://readwise.io/sync#instapaper" target="_blank" rel="noopener noreferrer" className={styles.link}>Instapaper</a> or <a href="https://readwise.io/sync#pocket" target="_blank" rel="noopener noreferrer" className={styles.link}>Pocket</a> to automatically import any documents previously saved there.</p>
        </div>
      </div>
    );
  }

  if (isEmptyLibraryCategory) {
    return (
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyBlock}>
          <h3>Supported document types</h3>
          <p>You can save documents of all kinds to Reader including web articles, RSS feeds, email newsletters, PDFs, EPUBs, and Twitter threads bringing all your reading into one place.</p>
          <p>Explore the icons in the left navigation sidebar (or use the keyboard shortcuts <span className={styles.shortcut}>2</span> through <span className={styles.shortcut}>7</span>) to learn how to work with documents of each type.</p>
        </div>
        <BrowserExtensions />
        <MobileApps />
      </div>
    );
  }

  if (isEmptyFeedCategory) {
    const dedicatedEmail = profile?.custom_feed_email;

    return (
      <div className={styles.emptyStateContent}>
        <div className={styles.emptyBlock}>
          <h3>Feed versus Library</h3>
          <p>Feed is the default destination for documents that are “pushed” to you by others such as RSS subscriptions, email newsletters, and Twitter lists.</p>
          <p>Feed is separated from your Library to avoid drowning out documents that you&apos;ve intentionally chosen to save.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>RSS feeds</h3>
          <p>You can subscribe to RSS feeds a variety of ways. The easiest are to search for the feed you&apos;re interested in or upload an OPML file.</p>
          <div className={styles.buttons}>
            <Button className={`${styles.primary}`} variant="default" onClick={openFeedsSubMenu}>
              Add feed
            </Button>
            <Button className={`${styles.primary}`} variant="default" onClick={openFileDialog}>
              Browse files
            </Button>
          </div>
        </div>
        {dedicatedEmail &&
          <div className={styles.emptyBlock}>
            <h3>Email newsletters</h3>
            <p>You can subscribe to newsletters using your custom Reader email address (or setup an auto-forward within your email client).</p>
            <div className={styles.dedicatedEmail}>
              <span>{dedicatedEmail}</span>
              <Button className={`${styles.primary}`} variant="default" onClick={() => copyTextOnWeb(dedicatedEmail)}>
                Copy
              </Button>
            </div>
            {/* TODO: check if we need to actually hide this */}
            {/* <p><Button variant="unstyled" className={styles.link} onClick={null}>Send yourself a reminder email</Button> to effortlessly add your custom Reader addresses to your contact list.</p> */}
          </div>
        }
      </div>
    );
  }

  if (isEmptyPdfsCategory) {
    return (
      <div className={styles.emptyStateContent}>
        <img alt="upload" src={emptyStateImgSrc} />
        <div className={styles.emptyBlock}>
          <h3>Drag and drop</h3>
          <p>Upload PDFs by dragging and dropping files from your computer anywhere on top of the web app. File sizes up to {maxFileSizeMb} MB are supported.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Mobile app share sheet</h3>
          <p>Upload PDFs by launching the share sheet on iOS and selecting Save PDF to Reader.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email files as attachments</h3>
          <p>Upload PDFs by emailing the files as attachments to your custom Reader email address.</p>
        </div>
        <div className={styles.emptyBlock}>
        <h3>File picker</h3>
          <p>
            <Button className={`${styles.browseButton} ${styles.link}`} variant="unstyled" onClick={openFileDialog}>
            Select a PDF file
            </Button> from your computer.
          </p>
        </div>
      </div>
    );
  }

  if (isEmptyEpubsCategory) {
    return (
      <div className={styles.emptyStateContent}>
        <img alt="upload" src={emptyStateImgSrc} />
        <div className={styles.emptyBlock}>
          <h3>Drag and drop</h3>
          <p>Upload EPUBs by dragging and dropping files from your computer anywhere on top of the web app. File sizes up to {maxFileSizeMb} MB are supported.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>Email files as attachments</h3>
          <p>Upload EPUBs by emailing the files as attachments to your custom Reader email address.</p>
        </div>
        <div className={styles.emptyBlock}>
          <h3>File picker</h3>
          <p>
            <Button className={`${styles.browseButton} ${styles.link}`} variant="unstyled" onClick={openFileDialog}>
            Select an EPUB file
            </Button> from your computer.
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export const EmptyStateSidebarHeader = ({ isEmptyTweetsCategory = false, isEmptyEmailsCategory = false, isEmptyArticlesCategory = false, isEmptyLibraryCategory = false, isEmptyPdfsCategory = false, isEmptyEpubsCategory = false, isEmptyFeedCategory = false }) => {
  const isInboxZero = globalState(useCallback((state) => state.isInboxZero, []));
  const getTitle = () => {
    if (isEmptyLibraryCategory || isEmptyFeedCategory) {
      return 'Getting started';
    }

    if (isEmptyTweetsCategory) {
      return 'Save Twitter threads';
    }

    if (isEmptyEmailsCategory) {
      return 'Save emails';
    }

    if (isEmptyArticlesCategory) {
      return 'Save articles';
    }

    if (isEmptyPdfsCategory) {
      return 'Upload PDFs';
    }

    if (isEmptyEpubsCategory) {
      return 'Upload EPUBs';
    }
  };

  return (
    <div className={styles.titleContainer}>
      <h1>{getTitle()}</h1>
      {!isInboxZero && <Tooltip content="Close" placement="top-start">
        <Button
          onClick={() => setEmptyStateCategory(null, { userInteraction: 'unknown' })}
          className={styles.closeButton}>
          <StrokeCancelIcon text="close" />
        </Button>
      </Tooltip>
      }
    </div>
  );
};
