import ISO6391 from 'iso-639-1';
import map from 'lodash/map';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { overrideDocProps } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/overrides';
import { toggleDocumentMetadataOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import isComposing from '../../../../shared/foreground/utils/isComposing';
import { getSummaries } from '../../../../shared/summary';
import {
  AllowedCategoryOverrides,
  Category,
  CategoryToDisplayName,
  DocumentWithTransientData,
  OverridesForm, ShortcutId,
} from '../../../../shared/types';
import {
  isDocumentWithWordCount,
} from '../../../../shared/typeValidators';
import getFormattedDurationFromNow from '../../../../shared/utils/dates/getFormattedDurationFromNow';
import parseStringTimestamp from '../../../../shared/utils/dates/parseStringTimestamp';
import getDocumentCategoryOverrideOrReal from '../../../../shared/utils/getDocumentCategoryOverrideOrReal';
import getDocumentLanguage from '../../../../shared/utils/getDocumentLanguage';
import getDocumentLanguageDisplayName from '../../../../shared/utils/getDocumentLanguageDisplayName';
import getDocumentOverrideOrReal from '../../../../shared/utils/getDocumentOverrideOrReal';
import getDocumentPublishedDate from '../../../../shared/utils/getDocumentPublishedDate';
import getDocumentReadingProgress from '../../../../shared/utils/getDocumentReadingProgress';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { getReadingTimeDisplay } from '../../../../shared/utils/getTimeToRead';
import { useHotKeys, useHotKeysPreventDefault, useIsRightSidebarHidden } from '../../hooks/hooks';
import fixAndTrapFocus from '../../utils/fixAndTrapFocus';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import DatePicker from '../DatePicker';
import { Dialog } from '../Dialog';
import ReadOnlySystem from '../icons/ReadOnlySystemIcon';
import Overlay from '../Overlay';
import sidebarStyles from '../RightSidebar/RightSidebar.module.css';
import Tag from '../Tag';
import Tooltip from '../Tooltip';
import styles from './DocumentMetadata.module.css';
import EditableImage from './EditableImage';
import EditMetadataCombobox from './EditMetadataCombobox';

type OverridesFormKey = keyof OverridesForm;

export default function DocumentMetadata({ document, isReaderViewUrl }: {document: DocumentWithTransientData; isReaderViewUrl: boolean;}): JSX.Element {
  const metadataContainerRef = useRef<HTMLDivElement>(null);
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false);
  const sidebarsHidden = useIsRightSidebarHidden();
  const className = `${styles.documentMetadata} ${sidebarsHidden ? styles.hidden : ''}`;

  useEffect(() => {
    if (metadataContainerRef.current) {
      fixAndTrapFocus(metadataContainerRef.current);
    }
  }, [metadataContainerRef]);

  const [overrides, setOverrides] = useState({
    title: undefined,
    author: undefined,
    summary: undefined,
    language: undefined,
    tagsToRemove: undefined,
    docProgress: undefined,
    coverImageUrl: undefined,
    publishedDate: undefined,
    category: undefined,
  } as OverridesForm);

  const shortcutsMap = useShortcutsMap();
  const title = getDocumentTitle(document);
  const author = getDocumentOverrideOrReal(document, 'author');
  const summaries = getSummaries(document);
  const { summary } = summaries;
  const generatedSummary = summaries.generatedSummary || '';
  const tags = document.tags ?? [];
  const category = document ? CategoryToDisplayName[getDocumentCategoryOverrideOrReal(document)] : '';
  const publishedDate = useMemo(() => getDocumentPublishedDate(document), [document]);
  const length = isDocumentWithWordCount(document) ? getReadingTimeDisplay(document.word_count) : '';
  const savedAtDate = document.saved_at ? getFormattedDurationFromNow(parseStringTimestamp(document.saved_at)) : '';
  const coverImageUrl = getDocumentOverrideOrReal(document, 'image_url');
  const docProgress = useMemo(() => getDocumentReadingProgress(document), [document]);
  const categoriesToDisplay = AllowedCategoryOverrides.map((categoryName) => CategoryToDisplayName[categoryName]);
  const languageList = useMemo(() => ISO6391.getAllNames(), []);
  const languageCode = useMemo(() => getDocumentLanguage(document), [document]);
  const languageDisplayName = useMemo(() => getDocumentLanguageDisplayName(languageCode), [languageCode]);
  const buttonProgress = useMemo(() => {
    if (overrides.docProgress !== undefined) {
      return overrides.docProgress;
    }

    return getDocumentReadingProgress(document);
  }, [document, overrides.docProgress]);

  const progressButtonCopy = useMemo(() => {
    if (overrides.docProgress !== undefined) {
      return overrides.docProgress > 0 ? 'Mark unread' : 'Mark read';
    }

    return docProgress > 0 ? 'Mark unread' : 'Mark read';
  }, [docProgress, overrides.docProgress]);

  const hasChanges = useMemo(() => Object.keys(overrides).some((key) => {
    switch (key) {
      case 'title':
      case 'author':
      case 'language':
      case 'category':
      case 'generated_summary':
      case 'summary': {
        if (overrides[key] === undefined) {
          return false;
        }

        let actualValue;

        if (key === 'title') {
          actualValue = title;
        } else if (key === 'author') {
          actualValue = author;
        } else if (key === 'category') {
          actualValue = category;
        } else if (key === 'generated_summary') {
          actualValue = generatedSummary;
        } else if (key === 'language') {
          actualValue = languageCode;
        } else {
          actualValue = summary;
        }

        return overrides[key] !== actualValue;
      }

      case 'tagsToRemove': {
        if (!overrides.tagsToRemove) {
          return false;
        }

        return overrides.tagsToRemove.length > 0;
      }

      case 'docProgress': {
        if (overrides.docProgress === undefined) {
          return false;
        }

        return overrides.docProgress !== docProgress;
      }

      case 'coverImageUrl': {
        if (overrides.coverImageUrl === undefined) {
          return false;
        }

        return overrides.coverImageUrl !== coverImageUrl;
      }

      case 'publishedDate': {
        if (overrides.publishedDate === undefined) {
          return false;
        }

        return overrides.publishedDate !== publishedDate;
      }

      default:
        return false;
    }
  }), [overrides, title, author, category, generatedSummary, languageCode, summary, docProgress, coverImageUrl, publishedDate]);

  const onSaveChanges = useCallback(() => {
    if (!hasChanges) {
      return;
    }

    overrideDocProps(document.id, overrides, { userInteraction: 'keypress' });
    toggleDocumentMetadataOpen({ userInteraction: 'click' });
  }, [document.id, hasChanges, overrides]);

  const closeMetadataIfNoChanges = useCallback(() => {
    if (hasChanges) {
      setShowDiscardChangesDialog(true);
    } else {
      toggleDocumentMetadataOpen({ userInteraction: 'click' });
    }
  }, [hasChanges]);

  const updateOverrides = useCallback((key: OverridesFormKey, value: OverridesForm[OverridesFormKey]) => {
    setOverrides((prevOverrides) => ({ ...prevOverrides, [key]: value }));
  }, []);

  const addOverrideTag = (tagName: string) => {
    updateOverrides('tagsToRemove', [...overrides.tagsToRemove ?? [], tagName]);
  };

  const removeOverrideTag = (tagName: string) => {
    if (overrides.tagsToRemove) {
      updateOverrides('tagsToRemove', overrides.tagsToRemove.filter((tag) => tag !== tagName));
    }
  };

  const updateCategory = useCallback((newCategoryDisplayName: string) => {
    updateOverrides('category', Category[newCategoryDisplayName]);
  }, [updateOverrides]);

  const updateLanguage = useCallback((newLanguage: string) => {
    updateOverrides('language', ISO6391.getCode(newLanguage));
  }, [updateOverrides]);

  const updatePublishedDate = useCallback((newDate: Date) => {
    updateOverrides('publishedDate', newDate.getTime());
  }, [updateOverrides]);

  const toggleDocRead = () => {
    if (overrides.docProgress === undefined) {
      updateOverrides('docProgress', docProgress > 0 ? 0 : 100);
    } else {
      updateOverrides('docProgress', overrides.docProgress === 0 ? 100 : 0);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isComposing(event)) {
      return;
    }
    if (event.key === 'Escape') {
      closeMetadataIfNoChanges();
    } else if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      onSaveChanges();
    }
  };

  useHotKeys(
    shortcutsMap[ShortcutId.Enter],
    useCallback((e) => {
      const target = e.target as HTMLElement;
      target.click();
    }, []),
  );

  useHotKeysPreventDefault(
    shortcutsMap[ShortcutId.CmdOrCtrlAndEnter],
    useCallback(() => onSaveChanges(), [onSaveChanges]),
  );

  useHotKeys(
    shortcutsMap[ShortcutId.Esc],
    useCallback(() => closeMetadataIfNoChanges(), [closeMetadataIfNoChanges]),
  );

  return (
    <>
      <Overlay className={styles.documentMetadataOverlay} onClick={closeMetadataIfNoChanges} />

      {showDiscardChangesDialog &&
        <Dialog
          id="discard-document-metadata-changes"
          title="Discard changes?"
          subtitle="Are you sure you want to discard your changes?"
          actionTitle="Discard"
          cancelTitle="Cancel"
          action={() => toggleDocumentMetadataOpen({ userInteraction: 'click' })}
          cancelAction={() => setShowDiscardChangesDialog(false)}
        />
      }

      <div ref={metadataContainerRef} className={`${styles.metadataSidebar} ${sidebarStyles.sidebar} ${sidebarStyles.metadataSidebar} ${isReaderViewUrl ? sidebarStyles.sidebarReaderView : ''}`}>
        <div className={`${sidebarStyles.titleContainer} ${sidebarStyles.isEditMetadata}`}>
          <h1>Edit metadata</h1>
        </div>

        <div className={`${sidebarStyles.panelContainer} has-visible-scrollbar`}>
          <div className={className}>
            <div className={styles.metadataItem}>
              <label htmlFor="title" className={styles.label}>Title</label>
              <input
                defaultValue={title}
                name="title"
                autoComplete="false"
                aria-label="title"
                onChange={(e) => updateOverrides('title', e.target.value)}
                onKeyDown={onKeyDown}
                autoFocus
              />
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="author" className={styles.label}>Author</label>
              <input
                defaultValue={author}
                name="author"
                autoComplete="false"
                aria-label="author"
                onChange={(e) => updateOverrides('author', e.target.value)}
                onKeyDown={onKeyDown}
              />
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="summary" className={styles.label}>Summary</label>
              <textarea
                defaultValue={summary}
                name="summary"
                rows={6}
                autoComplete="false"
                aria-label="title"
                onChange={(e) => updateOverrides(generatedSummary.length > 0 ? 'generated_summary' : 'summary', e.target.value)}
                onKeyDown={onKeyDown}
              />
            </div>

            {Object.keys(tags).length > 0 && <div className={styles.metadataItem}>
              <label htmlFor="tags" className={styles.label}>Document tags</label>
              <div className={styles.tagsContainer}>
                {map(
                  tags,
                  ({ name, type }) => {
                    if (overrides.tagsToRemove?.includes(name)) {
                      return (
                        <Tag className={styles.removedTag} key={name} onClick={() => removeOverrideTag(name)} onClickAddIcon={() => removeOverrideTag(name)}>{type === 'generated' ? `#${name}` : name}</Tag>
                      );
                    } else {
                      return <Tag key={name} onClick={() => addOverrideTag(name)} onClickRemoveIcon={() => addOverrideTag(name)}>{type === 'generated' ? `#${name}` : name}</Tag>;
                    }
                  }
                  ,
                )}
              </div>
            </div>}

            <div className={styles.metadataItem}>
              <label htmlFor="category" className={styles.label}>Type</label>
                  <EditMetadataCombobox
                    value={category}
                    allOptions={categoriesToDisplay}
                    onValueSet={updateCategory}
                    optionType="category" />
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="published" className={styles.label}>Published</label>
                  <DatePicker onSaveDate={updatePublishedDate} originalDate={publishedDate} />
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="length" className={styles.label}>Length</label>
              <Tooltip content="Read-only. Set by document.">
                <div className={styles.readOnlyField}>
                  <input readOnly value={length} name="length" autoComplete="false" aria-label="title" />
                  <ReadOnlySystem />
                </div>
              </Tooltip>
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="saved" className={styles.label}>Saved</label>
              <Tooltip content="Read-only. Set by document.">
                <div className={styles.readOnlyField}>
                  <input readOnly value={savedAtDate} name="saved" autoComplete="false" aria-label="title" />
                  <ReadOnlySystem />
                </div>
              </Tooltip>
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="progress" className={styles.label}>Progress</label>
              <div className={styles.progressWrapper}>
                <Tooltip content="Read-only. Set by document.">
                  <div className={styles.readOnlyField}>
                    <input readOnly value={`${buttonProgress}%`} name="length" autoComplete="false" aria-label="title" />
                    <ReadOnlySystem />
                  </div>
                </Tooltip>
                <Button variant="default" onClick={toggleDocRead}>{progressButtonCopy}</Button>
              </div>
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="language" className={styles.label}>Language</label>
                <EditMetadataCombobox
                  value={languageDisplayName}
                  allOptions={languageList}
                  onValueSet={updateLanguage}
                  optionType="language" />
            </div>

            <div className={styles.metadataItem}>
              <label htmlFor="coverImageUrl" className={styles.label}>Cover image</label>
              <EditableImage
                defaultValue={coverImageUrl}
                name="coverImageUrl"
                onChange={(newCoverImage) => updateOverrides('coverImageUrl', newCoverImage)}
              />
          </div>
          </div>
        </div>

        <div className={`${sidebarStyles.bottom}`}>
          <Button onClick={closeMetadataIfNoChanges}>Cancel</Button>
          <Tooltip content="Save changes" shortcut={shortcutsMap[ShortcutId.CmdOrCtrlAndEnter]}>
            <button type="button" onClick={onSaveChanges} className={`${styles.saveButton} ${hasChanges ? '' : styles.isDisabled}`}>Save</button>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
