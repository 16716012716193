import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';

import FuseSearch from '../../../../shared/utils/FuseSearch';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import styles from './EditMetadataCombobox.module.css';

export default function EditMetadataCombobox({ value, allOptions, onValueSet, optionType }: { value: string; allOptions: string[]; onValueSet: (newValue: string) => void; optionType: string;}) {
  const [currentInput, setCurrentInput] = useState('');
  const [selected, setSelected] = useState(value);
  const [placeholder, setPlaceholder] = useState(value);

  const formatOptions = ({ value }: {value: string;}) => {
    const isSelected = selected === value;
    return <div>
      {isSelected && <span>
        <CheckmarkIcon className={styles.checkedIcon} />
      </span>}
      <span className={!isSelected ? styles.optionLabel : ''}>{value}</span>
    </div>;
  };

  const onChange = useCallback((selectedItem) => {
    if (selectedItem) {
      onValueSet(selectedItem.value);
      setSelected(selectedItem.value);
      setPlaceholder(selectedItem.value);
    } else {
      setPlaceholder(`Choose a ${optionType}...`);
    }
  }, [optionType, onValueSet]);

  const fuseSearchOptions = FuseSearch<string>(allOptions, currentInput, []);

  const filteredDropdownOptions = useMemo(() =>
      fuseSearchOptions.map((option) => ({
        value: option,
        label: option,
      })),
  [fuseSearchOptions]);

  return (
    <div>
      <Select
        isClearable
        className={styles.selectRoot}
        classNamePrefix="select"
        formatOptionLabel={formatOptions}
        placeholder={placeholder}
        options={filteredDropdownOptions}
        onInputChange={setCurrentInput}
        onChange={onChange}
        noOptionsMessage={() => 'No matches found'}
      />
    </div>
  );
}
