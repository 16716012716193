import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useHighlights } from '../../../../shared/foreground/database/helperHooks';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState } from '../../../../shared/foreground/models';
import {
  isNarrowScreenSize,
  useDocument,
} from '../../../../shared/foreground/stateHooks';
import useFocusedDocument from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import { setEmptyStateCategory } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import { toggleDocumentMetadataOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import {
  hideSidebars,
  toggleHideRightSidebar,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import useRefSetter from '../../../../shared/foreground/utils/useRefSetter';
import {
  AnyDocument,
  Category,
  DocumentLocation,
  DocumentWithParsedDocId,
  DocumentWithTransientData,
  FeedDocumentLocation,
  FirstClassDocument,
  Highlight,
  NotebookKind,
  RightSidebarVisiblePanel,
  ShortcutId,
} from '../../../../shared/types';
import {
  articlesQueries,
  emailsQueries,
  epubsQueries,
  pdfsQueries,
  tweetsQueries,
} from '../../../../shared/utils/filteredViews';
import getNextItemInArray from '../../../../shared/utils/getNextItemInArray';
import urlJoin from '../../../../shared/utils/urlJoin';
import { useHotKeys, useIsLeftSidebarHidden, useIsRightSidebarHidden, useNotebookViewParams } from '../../hooks/hooks';
import {
  getFilterViewQueryFromPathname,
  readerViewUrl,
} from '../../utils/pathnameHelpers';
import { useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import Button from '../Button';
import { CountLabel } from '../CountLabel';
import DocumentMetadata from '../DocumentMetadata/DocumentMetadata';
import DropdownNotebookExport from '../Dropdown/DropdownNotebookExport';
import HelpDropdown from '../Dropdown/HelpDropdown';
import NoItemSelectedIcon from '../icons/NoItemSelectedIcon';
import SolidCircleClear from '../icons/SolidCircleClear';
import ToggleRightPanelIcon from '../icons/ToggleRightPanelIcon';
import type { NotebookRouteParams } from '../NotebookView/NotebookPage';
import Overlay from '../Overlay';
import Tooltip from '../Tooltip';
import { TrialExpiryNotice } from '../TrialExpiryNotice';
import { DocumentInfoPanel } from './DocumentInfoPanel';
import { DocumentLinksPanel } from './DocumentLinksPanel';
import { DocumentNotebookPanel } from './DocumentNotebookPanel';
import { EmptyStateSidebarContent, EmptyStateSidebarHeader } from './EmptyStateSidebar';
import styles from './RightSidebar.module.css';


export interface DocumentPanelProps {
  sidebarsHidden: boolean;
  document: DocumentWithTransientData<DocumentWithParsedDocId>;
}


const NavButton = ({
  active,
  name,
  onClick,
  comingSoon = false,
  count = 0,
  showNumber = false,
}: { active: boolean; name: RightSidebarVisiblePanel; onClick: () => void; comingSoon?: boolean; count?: number; showNumber?: boolean; }) => {
  return <Tooltip content="Coming soon" disabled={!comingSoon}>
    <div className={`${styles.navButton} ${active ? `${styles.active}` : ''}`}>
      <button type="button" onClick={onClick}>
        {name}
      </button>
      {showNumber && <CountLabel count={count} isActive={active} />}
    </div>
  </Tooltip>;
};

const ToggleRightPanelButton = () => {
  const shortcutsMap = useShortcutsMap();

  return (
    <Tooltip content="Hide right panel" shortcut={shortcutsMap[ShortcutId.HideRightPanel]}>
      <Button
        className={styles.toggleRightPanelWrapper} onClick={() => {
          toggleHideRightSidebar({ userInteraction: 'click' });
        }}>
        <ToggleRightPanelIcon />
      </Button>
    </Tooltip>
  );
};

function DocumentViewSidebar({ document }: {
  document?: DocumentWithTransientData<FirstClassDocument> | null;
}) {
  return <MultiPanelSidebar
    document={document}
    enabledPanels={[
      RightSidebarVisiblePanel.DocumentInfo,
      RightSidebarVisiblePanel.DocumentNotebook,
      RightSidebarVisiblePanel.DocumentLinks,
    ]} />;
}

export const RightSidebar = React.memo(function RightSidebar() {
  const { pathname } = useLocation();
  const isReaderViewUrl = readerViewUrl.test(pathname);
  const isDocumentMetadataShown = globalState(useCallback((state) => state.isDocumentMetadataShown, []));
  const [focusedDocument] = useFocusedDocument();
  const notebookViewParams = useNotebookViewParams();

  if (notebookViewParams) { // This is a notebook view
    return <NotebookViewSidebar {...notebookViewParams} />;
  }

  if (isDocumentMetadataShown && focusedDocument) {
    return <DocumentMetadata document={focusedDocument} isReaderViewUrl={isReaderViewUrl} />;
  }

  return <DocumentViewSidebar document={focusedDocument} />;
});

interface EmptyProps {
  isEmptyTweetsCategory: boolean;
  isEmptyEmailsCategory: boolean;
  isEmptyArticlesCategory: boolean;
  isEmptyLibraryCategory: boolean;
  isEmptyPdfsCategory: boolean;
  isEmptyEpubsCategory: boolean;
  isEmptyFeedCategory: boolean;
}

interface HeaderProps {
  isEmptyState: boolean;
  rightSidebarHidden: boolean;
  visiblePanel: RightSidebarVisiblePanel;
  setVisiblePanel: (panel: RightSidebarVisiblePanel) => void;
  noDocSelected?: boolean;
  enabledPanels: RightSidebarVisiblePanel[];
  countLabelForPanel: { [panel in RightSidebarVisiblePanel]?: number; };
}

const Header = React.memo(function Header({
  isEmptyState,
  rightSidebarHidden,
  noDocSelected = false,
  visiblePanel,
  setVisiblePanel,
  enabledPanels,
  countLabelForPanel,
  isEmptyTweetsCategory = false,
  isEmptyEmailsCategory = false,
  isEmptyArticlesCategory = false,
  isEmptyLibraryCategory = false,
  isEmptyPdfsCategory = false,
  isEmptyEpubsCategory = false,
  isEmptyFeedCategory = false,
}: HeaderProps & EmptyProps) {
  const navButtons = useMemo(() =>
      enabledPanels.map((panel) =>
        <NavButton
          key={panel}
          active={visiblePanel === panel}
          name={panel}
          onClick={() => setVisiblePanel(panel)}
          showNumber={countLabelForPanel[panel] !== undefined}
          count={countLabelForPanel[panel]} />)
    ,
    [enabledPanels, countLabelForPanel, visiblePanel, setVisiblePanel]);

  if (noDocSelected) {
    return <div className={`${styles.navContainer} ${rightSidebarHidden ? styles.hidden : ''} `}>
      <ToggleRightPanelButton />
    </div>;
  }

  const emptyProps = {
    isEmptyLibraryCategory,
    isEmptyFeedCategory,
    isEmptyArticlesCategory,
    isEmptyPdfsCategory,
    isEmptyEpubsCategory,
    isEmptyEmailsCategory,
    isEmptyTweetsCategory,
  };
  if (isEmptyState) {
    return <EmptyStateSidebarHeader {...emptyProps} />;
  }

  return (
    <>
      <div className={`${styles.navContainer} ${rightSidebarHidden ? styles.hidden : ''} `}>
        {navButtons}

        <ToggleRightPanelButton />
      </div>
      <div className={styles.dividerWrapper}>
        <div className={styles.divider} />
      </div>
    </>
  );
});

const EmptyStateInstructionsFooter = () => {
  const isInboxZero = globalState(useCallback((state) => state.isInboxZero, []));

  if (isInboxZero) {
    return null;
  }

  return (
    <div className={styles.bottom}>
      <Tooltip content="Close" placement="top-start">
        <Button onClick={() => setEmptyStateCategory(null, { userInteraction: 'unknown' })} className={styles.closeButton}>
          <SolidCircleClear />
        </Button>
      </Tooltip>
    </div>
  );
};

const Footer = ({ isEmptyState, document, rightSidebarHidden, visiblePanel, disableNotebookView }: {
  isEmptyState: boolean;
  document?: AnyDocument | null;
  rightSidebarHidden: boolean;
  visiblePanel: RightSidebarVisiblePanel;
  disableNotebookView: boolean;
}) => {
  const location = useLocation();
  const shortcutsMap = useShortcutsMap();

  if (!document) {
    return null;
  }

  if (isEmptyState) {
    return <EmptyStateInstructionsFooter />;
  }

  let actionButtons: JSX.Element | undefined;
  if (visiblePanel === RightSidebarVisiblePanel.DocumentInfo) {
    actionButtons =
      <Tooltip content="Edit document metadata" placement="top" shortcut={shortcutsMap[ShortcutId.ShowDocMetadata]}>
        <Button onClick={() => toggleDocumentMetadataOpen({ userInteraction: 'click' })}>Edit metadata</Button>
      </Tooltip>;
  } else if (visiblePanel === RightSidebarVisiblePanel.DocumentNotebook) {
    if (document) { // do this or currentDoc?
      actionButtons = <>
        <DropdownNotebookExport docId={document.id} />
          <Tooltip content="Open notebook in full screen view" shortcut={shortcutsMap[ShortcutId.ToggleNotebookView]} placement="top-start">
            <Link
              className={disableNotebookView ? styles.disabledButton : styles.button}
              to={{
                pathname: urlJoin(['/notebook', 'parent', document.id]),
                state: {
                  parentPath: location.pathname,
                },
              }}>
                Open
            </Link>
          </Tooltip>
      </>;
    }
  }

  return (
    <div className={`${styles.bottom} ${rightSidebarHidden ? styles.hidden : ''}`}>
      {actionButtons}
      <HelpDropdown triggerClassName={styles.helpButton} currentDoc={document} />
    </div>
  );
};


export const MultiPanelSidebar = React.memo(function MultiPanelSidebar({
  document,
  enabledPanels,
}: {
  document?: DocumentWithTransientData<FirstClassDocument> | null;
  enabledPanels: RightSidebarVisiblePanel[];
}) {
  const { pathname } = useLocation();
  const isReaderViewUrl = readerViewUrl.test(pathname);
  const [visiblePanel, setVisiblePanel] = useState<RightSidebarVisiblePanel>(RightSidebarVisiblePanel.DocumentInfo);
  const panelContainerRef = useRef<HTMLDivElement>(null);
  const currentQuery = useMemo(() => getFilterViewQueryFromPathname(pathname) || '', [pathname]);
  const isArticlesCategory = useMemo(() => articlesQueries.includes(currentQuery), [currentQuery]);
  const isEmailsCategory = useMemo(() => emailsQueries.includes(currentQuery), [currentQuery]);
  const isTweetsCategory = useMemo(() => tweetsQueries.includes(currentQuery), [currentQuery]);
  const isPdfsCategory = useMemo(() => pdfsQueries.includes(currentQuery), [currentQuery]);
  const isEpubsCategory = useMemo(() => epubsQueries.includes(currentQuery), [currentQuery]);
  const documentLocations = useDocumentLocations();
  const libraryViews = useMemo(() => documentLocations.map((view) => `/${view}`), [documentLocations]);
  const isLibraryCategory = useMemo(() => libraryViews.includes(pathname), [libraryViews, pathname]);
  const isFeedCategory = useMemo(() => Boolean(pathname.match(`/${DocumentLocation.Feed}/(${Object.values(FeedDocumentLocation).join('|')})`)), [pathname]);
  const emptyStateCategory = globalState(useCallback((state) => state.emptyStateCategory, []));
  const isInboxZero = globalState(useCallback((state) => state.isInboxZero, []));

  useEffect(() => {
    eventEmitter.on('set-visible-sidebar-panel', setVisiblePanel);
    return () => {
      eventEmitter.off('set-visible-sidebar-panel', setVisiblePanel);
    };
  }, []);

  useEffect(() => {
    if (enabledPanels.includes(visiblePanel)) {
      return;
    }
    setVisiblePanel(enabledPanels[0]);
  }, [enabledPanels, visiblePanel, setVisiblePanel]);

  const shortcutsMap = useShortcutsMap();
  useHotKeys(
    shortcutsMap[ShortcutId.SwitchRightSidebarTab],
    useCallback(() => {
      setVisiblePanel((previous) => {
        const nextPanel = getNextItemInArray({
          currentIndex: enabledPanels.indexOf(previous),
          list: enabledPanels,
        });
        return nextPanel ?? previous;
      });
    }, [
      enabledPanels,
      setVisiblePanel,
    ]),
    {
      description: 'Cycle forward through document sidebar tabs',
    },
  );
  useHotKeys(
    shortcutsMap[ShortcutId.SwitchRightSidebarTabBackward],
    useCallback(() => {
      setVisiblePanel((previous) => {
        const nextPanel = getNextItemInArray({
          currentIndex: enabledPanels.indexOf(previous),
          direction: 'backward',
          list: enabledPanels,
        });
        return nextPanel ?? previous;
      });
    }, [
      enabledPanels,
      setVisiblePanel,
    ]),
    {
      description: 'Cycle backward through document sidebar tabs',
    },
  );

  const isEmptyLibraryCategory = useMemo(() => {
    return isLibraryCategory && isInboxZero;
  }, [isLibraryCategory, isInboxZero]);

  const isEmptyFeedCategory = useMemo(() => {
    return isFeedCategory && isInboxZero || emptyStateCategory === Category.RSS;
  }, [isFeedCategory, isInboxZero, emptyStateCategory]);

  const isEmptyPdfsCategory = useMemo(() => {
    return isPdfsCategory && isInboxZero || emptyStateCategory === Category.PDF;
  }, [isPdfsCategory, isInboxZero, emptyStateCategory]);

  const isEmptyEpubsCategory = useMemo(() => {
    return isEpubsCategory && isInboxZero || emptyStateCategory === Category.EPUB;
  }, [isEpubsCategory, isInboxZero, emptyStateCategory]);

  const isEmptyArticlesCategory = useMemo(() => {
    return isArticlesCategory && isInboxZero || emptyStateCategory === Category.Article;
  }, [isArticlesCategory, isInboxZero, emptyStateCategory]);

  const isEmptyEmailsCategory = useMemo(() => {
    return isEmailsCategory && isInboxZero || emptyStateCategory === Category.Email;
  }, [isEmailsCategory, isInboxZero, emptyStateCategory]);

  const isEmptyTweetsCategory = useMemo(() => {
    return isTweetsCategory && isInboxZero || emptyStateCategory === Category.Tweet;
  }, [isTweetsCategory, isInboxZero, emptyStateCategory]);

  const highlights = useHighlights({
    parentDocId: document?.id,
  });

  const [currentTags, setCurrentTags] = useState(document?.tags);
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const rightSidebarHidden = useIsRightSidebarHidden();

  const emptyProps = useMemo(() => ({
    isEmptyLibraryCategory,
    isEmptyFeedCategory,
    isEmptyArticlesCategory,
    isEmptyPdfsCategory,
    isEmptyEpubsCategory,
    isEmptyEmailsCategory,
    isEmptyTweetsCategory,
  }), [
    isEmptyLibraryCategory,
    isEmptyFeedCategory,
    isEmptyArticlesCategory,
    isEmptyPdfsCategory,
    isEmptyEpubsCategory,
    isEmptyEmailsCategory,
    isEmptyTweetsCategory,
  ]);

  const isEmptyState = useMemo(() => Boolean(Object.values(emptyProps).find((value) => value)) || !document, [document, emptyProps]);

  const getPanelContent = useCallback(() => {
    if (!document) {
      return (
        <div className={styles.noItemSelected}>
          <NoItemSelectedIcon />
          <p>No item selected</p>
        </div>
      );
    }

    if (isEmptyState) {
      return <EmptyStateSidebarContent {...emptyProps} />;
    }

    if (visiblePanel === RightSidebarVisiblePanel.DocumentNotebook) {
      return (
        <DocumentNotebookPanel
          doc={document}
          sidebarsHidden={rightSidebarHidden}
          highlights={highlights}
          isReaderViewUrl={isReaderViewUrl}
        />
      );
    } else if (visiblePanel === RightSidebarVisiblePanel.DocumentInfo) {
      return <DocumentInfoPanel document={document} sidebarsHidden={rightSidebarHidden} />;
    } else if (visiblePanel === RightSidebarVisiblePanel.NotebookParentInfo) {
      return <DocumentInfoPanel document={document} sidebarsHidden={rightSidebarHidden} isNotebookView />;
    } else if (visiblePanel === RightSidebarVisiblePanel.DocumentLinks) {
      return <DocumentLinksPanel document={document} sidebarsHidden={rightSidebarHidden} />;
    }

    return null;
  }, [document, emptyProps, highlights, isEmptyState, isReaderViewUrl, rightSidebarHidden, visiblePanel]);

  useEffect(() => {
    if (!isReaderViewUrl || leftSidebarHidden || rightSidebarHidden) {
      return;
    }

    if (isNarrowScreenSize(window.innerWidth)) {
      hideSidebars(true, { userInteraction: 'unknown' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReaderViewUrl]);

  useEffect(() => {
    if (isNarrowScreenSize(window.innerWidth)) {
      hideSidebars(true, { userInteraction: 'unknown' });
    }
  }, [screenWidth]);

  const docListScrolled = globalState(useCallback((state) => state.documentsListScrolled, []));

  useEffect(() => {
    // A tag was just added, unfade the taskbar for a few seconds to show that
    if (document && currentTags !== document.tags) {
      setCurrentTags(document.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReaderViewUrl, docListScrolled, document]);

  const scrollToHighlight = useCallback(({ id }: { id: Highlight['id']; }) => {
    if (!panelContainerRef.current) {
      return;
    }
    const highlight = panelContainerRef.current.querySelector(`[data-highlight-id="${id}"]`);
    if (!highlight) {
      return;
    }
    highlight.scrollIntoView();
  }, [panelContainerRef]);

  useEffect(() => {
    eventEmitter.on('document-sidebar:scrollToHighlight', scrollToHighlight);
    eventEmitter.on('document-sidebar:setVisiblePanel', setVisiblePanel);

    return () => {
      eventEmitter.off('document-sidebar:setVisiblePanel', scrollToHighlight);
      eventEmitter.off('document-sidebar:setVisiblePanel', setVisiblePanel);
    };
  }, [scrollToHighlight]);

  const shouldDisableNotebook = useMemo((): boolean => highlights.length === 0, [highlights]);

  const stopKeyboardEventPropagation = useCallback((event: KeyboardEvent) => event.stopPropagation(), []);
  const tabShortcutRef = useHotKeys(shortcutsMap[ShortcutId.Tab], stopKeyboardEventPropagation);
  const shiftTabShortcutRef = useHotKeys(shortcutsMap[ShortcutId.PreviousTab], stopKeyboardEventPropagation);
  const linksInMyLibraryCount = useMemo(() =>
    document?.transientData.links?.filter((link) => link.other_document).length, [document]);
  const countLabelForPanel = {
    [RightSidebarVisiblePanel.DocumentNotebook]: highlights.length + (document?.notes ? 1 : 0),
    [RightSidebarVisiblePanel.DocumentLinks]: linksInMyLibraryCount,
  };

  return <>
  {Boolean(emptyStateCategory) && <Overlay className={styles.emptyStateSidebarOverlay} onClick={() => setEmptyStateCategory(null, { userInteraction: 'click' })} />}
  <div
    className={`${styles.sidebar} ${isReaderViewUrl ? styles.sidebarReaderView : ''} ${rightSidebarHidden ? '' : styles.showSidebar} ${isEmptyState ? styles.isEmptyState : ''}`}
    ref={useRefSetter(tabShortcutRef, shiftTabShortcutRef)}>
    <Header
      isEmptyState={isEmptyState}
      noDocSelected={!document}
      rightSidebarHidden={rightSidebarHidden}
      visiblePanel={visiblePanel}
      setVisiblePanel={setVisiblePanel}
      enabledPanels={enabledPanels}
      countLabelForPanel={countLabelForPanel}
      {...emptyProps}
    />

    <div className={`${styles.panelContainer} has-visible-scrollbar`} ref={panelContainerRef}>
      {getPanelContent()}
    </div>
    <TrialExpiryNotice inSidebar />
    <Footer
      isEmptyState={isEmptyState}
      document={document}
      rightSidebarHidden={rightSidebarHidden}
      visiblePanel={visiblePanel}
      disableNotebookView={shouldDisableNotebook} />
  </div>
  </>;
});

export function NotebookViewSidebar({ notebookKind, notebookId }: NotebookRouteParams): ReactElement {
  const [parentDocument] = useDocument<FirstClassDocument>(notebookId);
  switch (notebookKind) {
    case NotebookKind.SingleParent: {
      return <MultiPanelSidebar
        document={parentDocument}
        enabledPanels={[RightSidebarVisiblePanel.NotebookParentInfo]}
      />;
    }
  }
}
