import React, { CSSProperties, forwardRef } from 'react';

import styles from './BabyGhost.module.css';

type Props = {
  className: string | undefined;
  isShown: boolean;
  onClick?: () => void;
  style?: CSSProperties;
};

export default forwardRef<never, Props>(function BabyGhost({ className, isShown, ...props }: Props, ref) {
  const classes = [styles.babyGhost, className];
  if (isShown) {
    classes.push(styles.show);
  } else {
    classes.push(styles.hide);
  }

  return (
    <div {...props} className={classes.join(' ')} ref={ref}>
      <img alt="" src="/BabyGhost.svg" />
    </div>
  );
});
