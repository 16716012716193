import type { AnyDocument, PartialDocument } from '../../types';
import { isDocumentWithWordCount } from '../../typeValidators';
import { getReadingTimeDisplay } from '../../utils/getTimeToRead';

export const getReadingPositionInfo = (currentDoc: AnyDocument | PartialDocument<AnyDocument, 'category' | 'readingPosition' | 'word_count' | 'overrides'>, overrideDocProgress?: number): string => {
  let scrollDepth = currentDoc.readingPosition?.scrollDepth ?? 0;
  if (overrideDocProgress !== undefined) {
    scrollDepth = overrideDocProgress / 100;
  }
  let readingPositionInfo = `${Math.floor(scrollDepth * 100)}%`;
  if (scrollDepth < 1 && isDocumentWithWordCount(currentDoc) && currentDoc.word_count) {
    readingPositionInfo += ` (${getReadingTimeDisplay(currentDoc.word_count * (1 - scrollDepth), false)} left)`;
  }
  return readingPositionInfo;
};
