// This helper makes sure you can't tab out of the container element
// It also programmatically focuses the next/previous element because
// on Safari if you press tab it doesn't focus on buttons :(

export default function fixAndTrapFocus(element: HTMLElement) {
  const focusableEls = Array.from(element.querySelectorAll('button, textarea, input, select')) as HTMLElement[];
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];
  const keyCodeTab = 9;

  element.addEventListener('keydown', (e) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === keyCodeTab;

    if (!isTabPressed) {
      return;
    }

    const currentIndex = focusableEls.findIndex((el) => el === document.activeElement);

    if (currentIndex === -1) {
      return;
    }

    e.preventDefault();

    const prevIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    if (e.shiftKey) {
      // shift + tab
      (focusableEls[prevIndex] || lastFocusableEl).focus();
    } else {
      // tab
      (focusableEls[nextIndex] || firstFocusableEl).focus();
    }
  });
}
