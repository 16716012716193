import { useCallback } from 'react';

import useLiveValueRef from './useLiveValueRef';

/*
  Use at your own risk
*/
export default function useRefSetter <T>(...refs: (React.MutableRefObject<T> | undefined)[]): (value: T) => void {
  const refsRef = useLiveValueRef(refs);

  return useCallback((value: T) => {
    for (const ref of refsRef.current) {
      if (typeof ref === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    }
  }, [refsRef]);
}
