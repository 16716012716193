import { AnyDocument, PartialDocument } from '../types';
import getDocumentAuthor from './getDocumentAuthor';
import getWords from './getWords';
import { isValidHttpUrl } from './isValidHttpUrl';


export default (doc?: AnyDocument | PartialDocument<AnyDocument, 'source_specific_data' | 'author' | 'overrides' | 'category'>, prefix = '@'): string => {
  const defaultValue = `${prefix}readwise`;

  if (!doc) {
    return defaultValue;
  }

  if (doc.source_specific_data?.tweet?.author_twitter_screen_name) {
    return `${prefix}${doc.source_specific_data?.tweet?.author_twitter_screen_name}`;
  }

  const authorOrUrl = getDocumentAuthor(doc);

  if (!authorOrUrl) {
    return defaultValue;
  }

  const handle = isValidHttpUrl(authorOrUrl) ? getHandleFromUrl(authorOrUrl) : getHandleFromAuthorName(authorOrUrl);
  return `${prefix}${handle}`;
};

// https://www.foo.com.ar -> foo
// https://www.foo.com -> foo
// http://www.foo.com -> foo
// www.foo.com -> foo
// foo.com -> foo
function getHandleFromUrl(url: string): string {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')[0];
}

// Rick Sanchez -> RickSanchez
// RickSanchez -> RickSanchez
// Rick -> Rick
function getHandleFromAuthorName(name: string): string {
  return getWords(name, 'unknown').join('');
}
