import { AnyDocument, PartialDocument } from '../types';
import { isDocumentWithWordCount } from '../typeValidators';
import getListeningTime from './getListeningTime';
import { getTimeFromSeconds } from './getTimeFromSeconds';
import { getReadingTimeDisplay } from './getTimeToRead';

export default (doc: AnyDocument | PartialDocument<AnyDocument, 'url' | 'category' | 'listening_time_seconds' | 'word_count'>): string | undefined => {
  const listeningTimeInSeconds = getListeningTime(doc);
  if (isDocumentWithWordCount(doc) && doc.word_count) {
    return getReadingTimeDisplay(doc.word_count);
  } else if (listeningTimeInSeconds) {
    return getTimeFromSeconds(listeningTimeInSeconds);
  }
};
